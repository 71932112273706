import React from 'react'
import { format, parseISO } from 'date-fns'

import Header from './Header'
import Footer from './Footer'
import Theme from './Theme'

const Post = ({ site, post }) => {
  return (
    <div className='body site wrapper'>
      <Theme data={site.theme} />
      <Header site={site} />

      <article className='container document'>
        <div className='container -max-8'>
          <h1>{post.title}</h1>

          {(site.theme.post.showDate === 'true' || site.theme.post.showAuthor === 'true') && (
            <div className='site-collection_meta -inline mt-16 mb-24'>
              {post.author?.name && post.author?.avatar_url && site.theme.post.showAuthor === 'true' && (
                <a className='site-collection_avatar -small'>{<img src={post.author.avatar_url} alt={post.author.name} />}</a>
              )}

              {post.author?.name && site.theme.post.showAuthor === 'true' && (
                <a className='site-collection_author mr-16'>{post.author.name}</a>
              )}

              {site.theme.post.showDate === 'true' && <time>{format(parseISO(post.published_at), 'MMM d')}</time>}
            </div>
          )}
        </div>

        <div
          className='document_content'
          dangerouslySetInnerHTML={{
            __html: post.html
          }}
        />

        <aside className='document_meta'>
          <div className='flex -align-center -wrap mt-24'>
            {post.tags.map(tag => (
              <a className='tag' key={tag.slug}>
                {tag.name}
              </a>
            ))}
          </div>
        </aside>
      </article>

      <Footer site={site} showLinksInHero={false} />
    </div>
  )
}

export default Post
