import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { find, findIndex, findLastIndex, intersection, isEqual, reject, uniq } from 'lodash'
import classNames from 'classnames'
import { useFieldArray, useForm } from 'react-hook-form'
import { serialize } from 'object-to-formdata'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { HexColorPicker, HexColorInput } from 'react-colorful'
import chroma from 'chroma-js'

import XIcon from 'icons/x.svg'
import MinimizeIcon from 'icons/minimize.svg'
import MaximizeIcon from 'icons/maximize.svg'
import StarIcon from 'icons/star.svg'
import Field from './NewField'
import Input from './Input'
import Textarea from './Textarea'
import Dropdown from './Dropdown'
import Tabs from './Tabs'
import Home from './Site/Home'
import Blog from './Site/Blog'
import Post from './Site/Post'
import Accordion from './Accordion'
import Option from './Option'
import OptionButton from './OptionButton'
import fonts from './fonts'

const samplePosts = [
  {
    title: 'This is an example post',
    excerpt: 'This is the example post’s example excerpt. Let’s put some more words like a real post would have.',
    image_url: 'https://images.bloggi.co/520b8b5b.jpg',
    published_at: '2021-04-09T14:13:58Z',
    tags: [],
    truncated_html:
      '<p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Perspiciatis, animi, commodi veritatis dicta temporibus assumenda voluptatibus quo, quasi voluptate ab veniam molestiae numquam officiis voluptatem eligendi. Excepturi possimus eos modi.</p>',
    html: '<p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Perspiciatis, animi, commodi veritatis dicta temporibus assumenda voluptatibus quo, quasi voluptate ab veniam molestiae numquam officiis voluptatem eligendi. Excepturi possimus eos modi.</p>'
  },
  {
    title: 'And here’s another example post',
    excerpt: 'Look at that, the second post. So cool. Here are some more words so you can see what it would look like.',
    image_url: 'https://images.bloggi.co/6c98b94e.jpg',
    published_at: '2021-04-09T14:13:58Z',
    tags: [],
    truncated_html:
      '<p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Perspiciatis, animi, commodi veritatis dicta temporibus assumenda voluptatibus quo, quasi voluptate ab veniam molestiae numquam officiis voluptatem eligendi. Excepturi possimus eos modi.</p>',
    html: '<p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Perspiciatis, animi, commodi veritatis dicta temporibus assumenda voluptatibus quo, quasi voluptate ab veniam molestiae numquam officiis voluptatem eligendi. Excepturi possimus eos modi.</p>'
  },
  {
    title: 'How does it look?',
    excerpt: 'Nice, right? Yeah, that’s a ridiculously good looking site you’re making. You are awesome.',
    image_url: 'https://images.bloggi.co/4b9fd6f9.jpg',
    published_at: '2021-04-09T14:13:58Z',
    tags: [],
    truncated_html:
      '<p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Perspiciatis, animi, commodi veritatis dicta temporibus assumenda voluptatibus quo, quasi voluptate ab veniam molestiae numquam officiis voluptatem eligendi. Excepturi possimus eos modi.</p>',
    html: '<p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Perspiciatis, animi, commodi veritatis dicta temporibus assumenda voluptatibus quo, quasi voluptate ab veniam molestiae numquam officiis voluptatem eligendi. Excepturi possimus eos modi.</p>'
  }
]

const backgroundColors = ['custom', 'hsl(0,0%,100%)', 'hsl(0,0%,98%)', 'hsl(0,0%,10%)']

const accentColors = [
  'custom',
  'hsl(208,100%,64%)',
  'hsl(194,100%,64%)',
  'hsl(143,84%,60%)',
  'hsl(48,96%,60%)',
  'hsl(22,100%,64%)',
  'hsl(0,100%,66%)',
  'hsl(340,100%,70%)'
]

const textColors = ['custom', 'default']

export default function Design({ initialSite, token, pro, siteUrl, posts, homePage }) {
  posts = posts.length > 0 ? posts : samplePosts

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    control
  } = useForm({
    defaultValues: {
      currentPage: 'home',
      site: {
        ...initialSite,
        internal_links_attributes: initialSite.internal_links_attributes.map(item => ({
          ...item,
          id: item.id.toString(),
          hidden: `${!item.url && !item.text}`
        })),
        external_links_attributes: initialSite.external_links_attributes.map(item => ({
          ...item,
          id: item.id.toString(),
          hidden: `${!item.url && !item.text}`
        })),
        theme: initialSite.theme || {
          home: {
            format: 'auto',
            alignment: 'center'
          },
          posts: {
            format: 'list',
            showDate: 'true',
            showImage: 'true',
            showExcerpt: 'true',
            showAuthor: 'true',
            grid: {
              columns: '3',
              textPosition: 'below'
            },
            feed: {
              truncate: 'true'
            }
          },
          post: {
            showDate: 'true',
            showAuthor: 'true'
          },
          fonts: {
            base: 'Source Sans',
            text: 'Source Serif',
            heading: 'Source Sans',
            headingWeight: '700'
          },
          colors: {
            background: backgroundColors[1],
            backgroundSelection: backgroundColors[1],
            accent: initialSite.color !== '47a9ff' ? `#${initialSite.color}` : accentColors[1],
            accentSelection: initialSite.color !== '47a9ff' ? accentColors[0] : accentColors[1]
          }
        }
      }
    }
  })

  const form = watch()

  const {
    site: { theme }
  } = form

  useEffect(() => {
    axios.defaults.headers.common['X-CSRF-Token'] = token
    setValue('site.custom_home_page', initialSite.custom_home_page.toString())

    if (!theme.colors.textSelection) {
      setValue('site.theme.colors.textSelection', 'default')
    }

    if (!theme.home?.format) {
      setValue('site.theme.home.format', 'auto')
      setValue('site.theme.home.alignment', 'center')
    }

    if (!theme.posts.showAuthor) {
      setValue('site.theme.posts.showAuthor', 'false')
    }

    if (!theme.post.showAuthor) {
      setValue('site.theme.posts.showAuthor', 'true')
    }

    if (!pro && (!baseFont.free || !textFont.free || !headingsFont.free)) {
      setValue('site.theme.fonts.base', 'Source Sans')
    }
  }, [])

  const { fields: internalLinks, move: moveInternalLink } = useFieldArray({
    control,
    name: 'site.internal_links_attributes',
    keyName: 'uuid'
  })

  const { fields: externalLinks, move: moveExternalLink } = useFieldArray({
    control,
    name: 'site.external_links_attributes',
    keyName: 'uuid'
  })

  const [isFullscreen, setIsFullscreen] = useState(false)
  const [logo, setLogo] = useState(initialSite.logo_url)
  const [icon, setIcon] = useState(initialSite.icon_url)
  const [avatar, setAvatar] = useState(initialSite.avatar_url)
  const [activeTab, setActiveTab] = useState(0)
  const [activeContentItem, setActiveContentItem] = useState(0)
  const [activeLayoutItem, setActiveLayoutItem] = useState(0)
  const [activeFontsItem, setActiveFontsItem] = useState(0)
  const [activeColorsItem, setActiveColorsItem] = useState(0)
  const [customBackgroundColor, setCustomBackgroundColor] = useState(
    theme.colors.backgroundSelection === 'custom' ? theme.colors.background : '#ffffff'
  )
  const [customAccentColor, setCustomAccentColor] = useState(theme.colors.accentSelection === 'custom' ? theme.colors.accent : '#333333')
  const [customTextColor, setCustomTextColor] = useState(theme.colors.textSelection === 'custom' ? theme.colors.text : '#333333')
  const [defaultTextColor, setDefaultTextColor] = useState('#333333')
  const [backgroundColorPickerOpen, setBackgroundColorPickerOpen] = useState(false)
  const [accentColorPickerOpen, setAccentColorPickerOpen] = useState(false)
  const [textColorPickerOpen, setTextColorPickerOpen] = useState(false)
  const mountedRef = useRef()

  const baseFont = find(fonts, { name: theme.fonts.base })
  const textFont = find(fonts, { name: theme.fonts.text })

  const baseFonts = reject(fonts, 'headingOnly')
  const textFonts = [baseFont, ...baseFont.matches.map(name => find(fonts, { name }))]
  const headingFonts = uniq([
    baseFont,
    textFont,
    ...intersection(baseFont.headingMatches, textFont.headingMatches).map(name => find(fonts, { name }))
  ])

  const headingsFont = find(fonts, { name: theme.fonts.heading })

  const onSubmit = (data, event) => {
    const formData = serialize(data, { indices: true })
    const imageKeys = ['logo', 'icon', 'avatar']

    imageKeys.forEach(key => {
      if (data.site[key] && data.site[key][0]) {
        formData.set(`site[${key}]`, data.site[key][0])
      }
    })

    // Set correct link positions
    const visibleInternalLinks = internalLinks.filter(item => item.text || item.url)
    const hiddenInternalLinks = internalLinks.filter(item => !item.text && !item.url)

    internalLinks.forEach((item, index) => {
      formData.set(
        `site[internal_links_attributes][${index}][position]`,
        item.text || item.url ? findIndex(visibleInternalLinks, item) : findIndex(hiddenInternalLinks, item) + visibleInternalLinks.length
      )
    })

    const visibleExternalLinks = externalLinks.filter(item => item.text || item.url)
    const hiddenExternalLinks = externalLinks.filter(item => !item.text && !item.url)

    externalLinks.forEach((item, index) => {
      formData.set(
        `site[external_links_attributes][${index}][position]`,
        item.text || item.url ? findIndex(visibleExternalLinks, item) : findIndex(hiddenExternalLinks, item) + visibleExternalLinks.length
      )
    })

    axios
      .patch(`/settings`, formData, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(() => {
        setSavedForm(form)
      })
      .catch(error => {
        console.log(error)
      })

    event.preventDefault()
  }

  const handleFileChange = (file, callback) => {
    if (file) {
      const reader = new FileReader()

      reader.onload = event => {
        callback(event.target.result)
      }

      reader.readAsDataURL(file)
    }
  }

  useEffect(() => {
    if (form.site.logo && form.site.logo[0]) {
      handleFileChange(form.site.logo[0], setLogo)
    }
  }, [form.site.logo])

  useEffect(() => {
    if (form.site.icon && form.site.icon[0]) {
      handleFileChange(form.site.icon[0], setIcon)
    }
  }, [form.site.icon])

  useEffect(() => {
    if (form.site.avatar && form.site.avatar[0]) {
      handleFileChange(form.site.avatar[0], setAvatar)
    }
  }, [form.site.avatar])

  useEffect(() => {
    if (mountedRef.current) {
      setValue('site.theme.fonts.text', theme.fonts.base)
      setValue('site.theme.fonts.heading', theme.fonts.base)
      setValue('site.theme.fonts.headingWeight', '700')
    }
  }, [theme.fonts.base])

  useEffect(() => {
    if (mountedRef.current && !find(headingFonts, { name: theme.fonts.heading })) {
      setValue('site.theme.fonts.heading', headingFonts[0].name)
      setValue('site.theme.fonts.headingWeight', '700')
    }
  }, [theme.fonts.text])

  useEffect(() => {
    if (!headingsFont.headingWeights.includes(Number(theme.fonts.headingWeight))) {
      setValue('site.theme.fonts.headingWeight', '700')
    }
  }, [theme.fonts.heading])

  useEffect(() => {
    setValue('site.theme.baseFont', baseFont)
    setValue('site.theme.textFont', textFont)
    setValue('site.theme.headingFont', headingsFont)
  }, [theme.fonts.base, theme.fonts.text, theme.fonts.heading])

  useEffect(() => {
    if (theme.colors.backgroundSelection === 'custom') {
      setValue('site.theme.colors.background', customBackgroundColor)
    } else {
      setValue('site.theme.colors.background', theme.colors.backgroundSelection)
    }
  }, [theme.colors.backgroundSelection])

  useEffect(() => {
    if (theme.colors.accentSelection === 'custom') {
      setValue('site.theme.colors.accent', customAccentColor)
    } else {
      setValue('site.theme.colors.accent', theme.colors.accentSelection)
    }
  }, [theme.colors.accentSelection])

  const textColorMap = {
    custom: customTextColor,
    default: defaultTextColor
  }

  const backgroundLuminance = chroma(theme.colors.background).luminance()

  useEffect(() => {
    if (backgroundLuminance > 0.4) {
      if (chroma(theme.colors.background).hex() === '#ffffff') {
        setValue('site.theme.colors.gray90', '#111')
        setValue('site.theme.colors.gray80', '#222')
        setValue('site.theme.colors.gray70', '#333')
        setDefaultTextColor('#333')
        setValue('site.theme.colors.gray60', '#555')
        setValue('site.theme.colors.gray50', '#888')
        setValue('site.theme.colors.gray40', '#aaa')
        setValue('site.theme.colors.gray30', '#ddd')
        setValue('site.theme.colors.gray20', '#eee')
        setValue('site.theme.colors.gray10', '#f5f5f5')
        setValue('site.theme.colors.gray05', '#fafafa')
      } else {
        setValue('site.theme.colors.gray90', chroma(theme.colors.background).darken(5).hex())
        setValue('site.theme.colors.gray80', chroma(theme.colors.background).darken(4.6).hex())
        setValue('site.theme.colors.gray70', chroma(theme.colors.background).darken(4.3).hex())
        setDefaultTextColor(chroma(theme.colors.background).darken(4.2).hex())
        setValue('site.theme.colors.gray60', chroma(theme.colors.background).darken(3.4).hex())
        setValue('site.theme.colors.gray50', chroma(theme.colors.background).darken(2.4).hex())
        setValue('site.theme.colors.gray40', chroma(theme.colors.background).darken(1.6).hex())
        setValue('site.theme.colors.gray30', chroma(theme.colors.background).darken(0.6).hex())
        setValue('site.theme.colors.gray20', chroma(theme.colors.background).darken(0.3).hex())
        setValue('site.theme.colors.gray10', chroma(theme.colors.background).darken(0.2).hex())
        setValue('site.theme.colors.gray05', chroma(theme.colors.background).darken(0.1).hex())
      }
    } else {
      setValue('site.theme.colors.gray90', '#ffffff')
      setValue('site.theme.colors.gray80', '#ffffff')
      const gray70 = chroma(theme.colors.background).brighten(5).desaturate(0.5).hex()
      setValue('site.theme.colors.gray70', gray70)
      setDefaultTextColor(gray70)
      setValue('site.theme.colors.gray60', chroma(gray70).alpha(0.7).hex())
      setValue('site.theme.colors.gray50', chroma(gray70).alpha(0.6).hex())
      setValue('site.theme.colors.gray40', chroma(gray70).alpha(0.55).hex())
      setValue('site.theme.colors.gray30', chroma(gray70).alpha(0.15).hex())
      setValue('site.theme.colors.gray20', chroma(gray70).alpha(0.075).hex())
      setValue('site.theme.colors.gray10', chroma(gray70).alpha(0.048).hex())
      setValue('site.theme.colors.gray05', chroma(gray70).alpha(0.025).hex())
    }
  }, [theme.colors.background])

  useEffect(() => {
    setValue('site.theme.colors.accent90', chroma(theme.colors.accent).darken(1).hex())
    setValue('site.theme.colors.accent80', chroma(theme.colors.accent).darken(0.5).hex())
    setValue('site.theme.colors.accent70', chroma(theme.colors.accent).hex())
    setValue('site.theme.colors.accent60', chroma(theme.colors.accent).alpha(0.8).hex())
    setValue('site.theme.colors.accent50', chroma(theme.colors.accent).alpha(0.6).hex())
    setValue('site.theme.colors.accent40', chroma(theme.colors.accent).alpha(0.55).hex())
    setValue('site.theme.colors.accent30', chroma(theme.colors.accent).alpha(0.4).hex())
    setValue('site.theme.colors.accent20', chroma(theme.colors.accent).alpha(0.22).hex())
    setValue('site.theme.colors.accent10', chroma(theme.colors.accent).alpha(0.12).hex())
  }, [theme.colors.accent, theme.colors.background])

  useEffect(() => {
    if (theme.colors.textSelection) {
      if (theme.colors.textSelection === 'default') {
        setValue('site.theme.colors.text', theme.colors.gray70)
        setValue('site.theme.colors.textLight', theme.colors.gray50)
        setValue('site.theme.colors.textLighter', theme.colors.gray30)
      } else {
        setValue('site.theme.colors.text', customTextColor)
        setValue('site.theme.colors.textLight', chroma(customTextColor).alpha(0.6).hex())
        setValue('site.theme.colors.textLighter', chroma(customTextColor).alpha(0.3).hex())
      }
    }
  }, [theme.colors.background, theme.colors.textSelection, defaultTextColor, customTextColor])

  const renderNavigationItem = ({ item, index }) => (
    <div
      key={item.id}
      className={classNames('flex -align-center', {
        hidden: item.hidden === 'true'
      })}
    >
      <input type='hidden' defaultValue={item.id} {...register(`site.internal_links_attributes.${index}.id`)} />
      <input type='hidden' defaultValue={item.position} {...register(`site.internal_links_attributes.${index}.position`)} />
      <input type='hidden' defaultValue={item.hidden} {...register(`site.internal_links_attributes.${index}.hidden`)} />

      <Field
        label='Text'
        htmlFor={`site.internal_links_attributes.${index}.text`}
        error={errors?.site?.name && 'Required'}
        className='mr-16'
      >
        <Input
          small
          {...register(`site.internal_links_attributes.${index}.text`)}
          error={errors?.site?.name}
          defaultValue={item.text}
          autoComplete='off'
          onKeyDown={event => {
            if (event.key === 'Enter') {
              event.preventDefault()
            }
          }}
        />
      </Field>

      <Field label='URL' htmlFor={`site.internal_links_attributes.${index}.url`} error={errors?.site?.name && 'Required'}>
        <Input
          small
          {...register(`site.internal_links_attributes.${index}.url`)}
          error={errors?.site?.name}
          defaultValue={item.url}
          autoComplete='off'
          onKeyDown={event => {
            if (event.key === 'Enter') {
              event.preventDefault()
            }
          }}
        />
      </Field>

      <button
        className='button -smaller -square -destructive ml-4'
        style={{ marginTop: 22 }}
        type='button'
        onClick={event => {
          setValue(`site.internal_links_attributes.${index}.hidden`, 'true')
          setValue(`site.internal_links_attributes.${index}.url`, '')
          setValue(`site.internal_links_attributes.${index}.text`, '')
          moveInternalLink(index, internalLinks.length - 1)
          event.preventDefault()
        }}
      >
        <XIcon />
      </button>
    </div>
  )

  const renderLinksItem = ({ item, index }) => (
    <div
      key={item.id}
      className={classNames('flex -align-center', {
        hidden: item.hidden === 'true'
      })}
    >
      <input type='hidden' defaultValue={item.id} {...register(`site.external_links_attributes.${index}.id`)} />
      <input type='hidden' defaultValue={item.position} {...register(`site.external_links_attributes.${index}.position`)} />
      <input type='hidden' defaultValue={item.hidden} {...register(`site.external_links_attributes.${index}.hidden`)} />

      <Field
        label='Icon'
        htmlFor={`site.external_links_attributes.${index}.icon`}
        error={errors?.site?.name && 'Required'}
        className='mr-8'
      >
        <select defaultValue={item.icon} className='select -small' {...register(`site.external_links_attributes.${index}.icon`)}>
          <option value=''>None</option>
          <optgroup label='Symbols'>
            <option value='email'>Email</option>
            <option value='rss'>RSS</option>
            <option value='globe'>Website</option>
          </optgroup>
          <optgroup label='Brands'>
            <option value='angellist'>AngelList</option>
            <option value='applepodcasts'>Apple Podcasts</option>
            <option value='buymeacoffee'>Buy Me A Coffee</option>
            <option value='crunchbase'>Crunchbase</option>
            <option value='devto'>Dev.to</option>
            <option value='discord'>Discord</option>
            <option value='dribbble'>Dribbble</option>
            <option value='facebook'>Facebook</option>
            <option value='github'>GitHub</option>
            <option value='goodreads'>Goodreads</option>
            <option value='instagram'>Instagram</option>
            <option value='linkedin'>LinkedIn</option>
            <option value='mastodon'>Mastodon</option>
            <option value='medium'>Medium</option>
            <option value='patreon'>Patreon</option>
            <option value='pinterest'>Pinterest</option>
            <option value='producthunt'>ProductHunt</option>
            <option value='reddit'>Reddit</option>
            <option value='slack'>Slack</option>
            <option value='spotify'>Spotify</option>
            <option value='stackoverflow'>StackOverflow</option>
            <option value='telegram'>Telegram</option>
            <option value='tripadvisor'>TripAdvisor</option>
            <option value='twitter'>Twitter</option>
            <option value='unsplash'>Unsplash</option>
            <option value='vsco'>VSCO</option>
            <option value='whatsapp'>WhatsApp</option>
            <option value='youtube'>Youtube</option>
          </optgroup>
        </select>
      </Field>

      <Field
        label='Text'
        htmlFor={`site.external_links_attributes.${index}.text`}
        error={errors?.site?.name && 'Required'}
        className='mr-8'
      >
        <Input
          small
          {...register(`site.external_links_attributes.${index}.text`)}
          error={errors?.site?.name}
          defaultValue={item.text}
          autoComplete='off'
          onKeyDown={event => {
            if (event.key === 'Enter') {
              event.preventDefault()
            }
          }}
        />
      </Field>

      <Field label='URL' htmlFor={`site.external_links_attributes.${index}.url`} error={errors?.site?.name && 'Required'}>
        <Input
          small
          {...register(`site.external_links_attributes.${index}.url`)}
          error={errors?.site?.name}
          defaultValue={item.url}
          autoComplete='off'
          onKeyDown={event => {
            if (event.key === 'Enter') {
              event.preventDefault()
            }
          }}
        />
      </Field>

      <button
        className='button -smaller -square -destructive ml-2'
        style={{ marginTop: 22 }}
        type='button'
        onClick={event => {
          setValue(`site.external_links_attributes.${index}.hidden`, 'true')
          setValue(`site.external_links_attributes.${index}.url`, '')
          setValue(`site.external_links_attributes.${index}.text`, '')
          setValue(`site.external_links_attributes.${index}.icon`, '')
          moveExternalLink(index, internalLinks.length - 1)
          event.preventDefault()
        }}
      >
        <XIcon />
      </button>
    </div>
  )

  const renderSite = () => {
    if (form.currentPage === 'blog') {
      return <Blog site={{ ...form.site, logo, icon, avatar, name: initialSite.name }} posts={posts} />
    } else if (form.currentPage === 'post') {
      return <Post site={{ ...form.site, logo, icon, avatar, name: initialSite.name }} post={posts[0]} />
    } else {
      return <Home site={{ ...form.site, logo, icon, avatar, name: initialSite.name }} homePage={homePage} posts={posts} />
    }
  }

  useEffect(() => {
    if (
      form.site.custom_home_page === 'true' &&
      ((activeTab === 1 && activeLayoutItem === 1) || (activeTab === 0 && activeContentItem === 4))
    ) {
      setValue('currentPage', 'blog')
    } else if ((activeTab === 1 && activeLayoutItem === 2) || activeTab === 2) {
      setValue('currentPage', 'post')
    } else {
      setValue('currentPage', 'home')
    }
  }, [activeTab, activeContentItem, activeLayoutItem, form.site.custom_home_page])

  const handleBackgroundColorChange = value => {
    setCustomBackgroundColor(value)
    setValue('site.theme.colors.background', value)
  }

  const handleAccentColorChange = value => {
    setCustomAccentColor(value)
    setValue('site.theme.colors.accent', value)
  }

  const handleTextColorChange = value => {
    setCustomTextColor(value)
    setValue('site.theme.colors.text', value)
  }

  const [savedForm, setSavedForm] = useState(form)

  const content = [
    {
      name: 'General',
      content: (
        <div className='stack -form'>
          <ImageField
            label='Logo'
            imageClass='site-logo'
            help='Minimum height of 120px is recommended.'
            onRemove={() => {
              setValue('remove_logo', true)
              setLogo(null)
            }}
            url={logo}
            name='site.logo'
            register={register}
            disabled={!pro}
          />

          <Field label='Bloggi branding' htmlFor='site.proud'>
            <label className={classNames('option mt-8 text -small', { '-disabled': !pro })}>
              <input type='checkbox' {...register('site.proud')} disabled={!pro} />
              <span className='option_control'></span>
              Display <i>Made with Bloggi</i> branding in the footer.
            </label>
          </Field>

          {!pro && (
            <p className='text -smaller'>
              <a href='/settings/subscription'>Upgrade</a> to the <strong>Pro</strong> plan to enable these features.
            </p>
          )}

          <input type='hidden' {...register('remove_logo')} />
          <input type='hidden' {...register('remove_icon')} />
          <input type='hidden' {...register('remove_avatar')} />
        </div>
      )
    },
    {
      name: 'Navigation',
      content: (
        <div>
          <DragDropContext
            onDragEnd={result => {
              if (!result.destination || result.destination.index === result.source.index) {
                return
              }
              moveInternalLink(result.source.index, result.destination.index)
            }}
          >
            <Droppable droppableId='droppable'>
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {internalLinks.map((item, index) =>
                    item.hidden === 'false' ? (
                      <Draggable key={item.id} draggableId={item.id} index={index}>
                        {(provided, snapshot) => (
                          <div
                            className='mb-24'
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            tabIndex={null}
                          >
                            {renderNavigationItem({ item, index })}
                          </div>
                        )}
                      </Draggable>
                    ) : null
                  )}

                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          {internalLinks.map((item, index) => (item.hidden === 'true' ? renderNavigationItem({ item, index }) : null))}

          {form.site.internal_links_attributes?.filter(item => item.hidden === 'true').length > 0 && (
            <button
              className='button -secondary -small'
              onClick={event => {
                const addedIndex = findIndex(form.site.internal_links_attributes, { hidden: 'true' })
                const lastVisibleIndex = findLastIndex(form.site.internal_links_attributes, {
                  hidden: 'false'
                })
                setValue(`site.internal_links_attributes.${addedIndex}.hidden`, 'false')
                moveInternalLink(addedIndex, lastVisibleIndex + 1)
                event.preventDefault()
              }}
            >
              Add
            </button>
          )}
        </div>
      )
    },
    {
      name: 'Social links',
      content: (
        <div>
          <DragDropContext
            onDragEnd={result => {
              if (!result.destination || result.destination.index === result.source.index) {
                return
              }
              moveExternalLink(result.source.index, result.destination.index)
            }}
          >
            <Droppable droppableId='droppable'>
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {externalLinks.map((item, index) =>
                    item.hidden === 'false' ? (
                      <Draggable key={item.id} draggableId={item.id} index={index}>
                        {(provided, snapshot) => (
                          <div
                            className='mb-24'
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            tabIndex={null}
                          >
                            {renderLinksItem({ item, index })}
                          </div>
                        )}
                      </Draggable>
                    ) : null
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          {externalLinks.map((item, index) => (item.hidden === 'true' ? renderLinksItem({ item, index }) : null))}

          {form.site.external_links_attributes?.filter(item => item.hidden === 'true').length > 0 && (
            <button
              className='button -secondary -small'
              onClick={event => {
                const addedIndex = findIndex(form.site.external_links_attributes, { hidden: 'true' })
                const lastVisibleIndex = findLastIndex(form.site.external_links_attributes, {
                  hidden: 'false'
                })
                setValue(`site.external_links_attributes.${addedIndex}.hidden`, 'false')
                moveExternalLink(addedIndex, lastVisibleIndex + 1)
                event.preventDefault()
              }}
            >
              Add
            </button>
          )}
        </div>
      )
    },
    {
      name: 'Home',
      content: (
        <div className='stack -form'>
          <ImageField
            label='Avatar'
            removeName='remove_avatar'
            imageClass='avatar'
            help='Minimum size of 300x300px is recommended.'
            onRemove={() => {
              setValue('remove_avatar', true)
              setAvatar(null)
            }}
            url={avatar}
            name='site.avatar'
            register={register}
          />

          <Field label='Heading' htmlFor='site.heading' error={errors?.site?.heading && 'Required'}>
            <Input {...register('site.heading')} error={errors?.site?.heading} autoComplete='off' />
          </Field>

          <Field label='Tagline' htmlFor='site.description' error={errors?.site?.description}>
            <Textarea {...register('site.description')} error={errors?.site?.description} />
          </Field>

          <Field label='Blog' htmlFor='site.custom_home_page' className='text -small stack -gap-8'>
            <Option type='radio' {...register('site.custom_home_page')} value='false'>
              Display as home page
            </Option>

            <Option type='radio' {...register('site.custom_home_page')} value='true'>
              Display as separate page
              <div className='text -light -smaller mt-8'>
                <p>
                  Your blog will be available at <a href={siteUrl}>{siteUrl.split('//')[1]}/blog</a>.{' '}
                </p>
              </div>
            </Option>
          </Field>
        </div>
      )
    }
  ]

  if (form.site.custom_home_page === 'true') {
    content.push({
      name: 'Blog',
      content: (
        <div className='stack -form'>
          <Field label='Heading' htmlFor='site.blog_heading' error={errors?.site?.blog_heading && 'Required'}>
            <Input {...register('site.blog_heading')} error={errors?.site?.blog_heading} autoComplete='off' />
          </Field>

          <Field label='Tagline' htmlFor='site.blog_description' error={errors?.site?.blog_description}>
            <Textarea {...register('site.blog_description')} error={errors?.site?.blog_description} />
          </Field>
        </div>
      )
    })
  }

  useEffect(() => {
    if (!mountedRef.current) {
      mountedRef.current = true
    }
  }, [])

  return (
    <div className='design-page'>
      <form className='design-page_sidebar' onSubmit={handleSubmit(onSubmit)}>
        <h1>Design</h1>

        <Tabs
          active={activeTab}
          onChange={setActiveTab}
          data={[
            {
              name: 'Content',
              content: <Accordion activeItem={activeContentItem} onChange={setActiveContentItem} data={content} />
            },
            {
              name: 'Layout',
              content: (
                <Accordion
                  activeItem={activeLayoutItem}
                  onChange={setActiveLayoutItem}
                  data={[
                    {
                      name: 'Home',
                      content: (
                        <div className='stack -form'>
                          <Field label='Format'>
                            <div className='option-button-group'>
                              <OptionButton {...register('site.theme.home.format')} value='auto'>
                                Auto
                              </OptionButton>
                              <OptionButton {...register('site.theme.home.format')} value='compact'>
                                Compact
                              </OptionButton>
                              <OptionButton {...register('site.theme.home.format')} value='standard'>
                                Standard
                              </OptionButton>
                            </div>
                          </Field>

                          <Field label='Alignment'>
                            <div className='option-button-group'>
                              <OptionButton {...register('site.theme.home.alignment')} value='left'>
                                Left
                              </OptionButton>
                              <OptionButton {...register('site.theme.home.alignment')} value='center'>
                                Center
                              </OptionButton>
                            </div>
                          </Field>
                        </div>
                      )
                    },
                    {
                      name: 'Posts',
                      content: (
                        <div className='stack -form'>
                          <Field label='Format' htmlFor='site.posts'>
                            <div className='option-button-group'>
                              <OptionButton {...register('site.theme.posts.format')} value='feed'>
                                Feed
                              </OptionButton>
                              <OptionButton {...register('site.theme.posts.format')} value='list'>
                                List
                              </OptionButton>
                              <OptionButton {...register('site.theme.posts.format')} value='grid'>
                                Grid
                              </OptionButton>
                            </div>
                          </Field>

                          <Field
                            label='Truncate'
                            htmlFor='site.theme.posts.feed.truncate'
                            className={classNames({
                              hidden: theme.posts.format !== 'feed'
                            })}
                          >
                            <div className='option-button-group'>
                              <OptionButton {...register('site.theme.posts.feed.truncate')} value='false'>
                                No
                              </OptionButton>

                              <OptionButton {...register('site.theme.posts.feed.truncate')} value='true'>
                                Yes
                              </OptionButton>
                            </div>
                          </Field>

                          <Field
                            label='Columns'
                            htmlFor='site.theme.posts.grid.columns'
                            className={classNames({
                              hidden: theme.posts.format !== 'grid'
                            })}
                          >
                            <div className='option-button-group'>
                              <OptionButton {...register('site.theme.posts.grid.columns')} value='2'>
                                Two
                              </OptionButton>

                              <OptionButton {...register('site.theme.posts.grid.columns')} value='3'>
                                Three
                              </OptionButton>
                            </div>
                          </Field>

                          <Field
                            label='Show image'
                            className={classNames({
                              hidden: theme.posts.format === 'feed'
                            })}
                          >
                            <div className='option-button-group'>
                              <OptionButton {...register('site.theme.posts.showImage')} value='false'>
                                No
                              </OptionButton>
                              <OptionButton {...register('site.theme.posts.showImage')} value='true'>
                                Yes
                              </OptionButton>
                            </div>
                          </Field>

                          <Field
                            label='Show excerpt'
                            className={classNames({
                              hidden: theme.posts.format === 'feed'
                            })}
                          >
                            <div className='option-button-group'>
                              <OptionButton {...register('site.theme.posts.showExcerpt')} value='false'>
                                No
                              </OptionButton>
                              <OptionButton {...register('site.theme.posts.showExcerpt')} value='true'>
                                Yes
                              </OptionButton>
                            </div>
                          </Field>

                          <Field label='Show author'>
                            <div className='option-button-group'>
                              <OptionButton {...register('site.theme.posts.showAuthor')} value='false'>
                                No
                              </OptionButton>
                              <OptionButton {...register('site.theme.posts.showAuthor')} value='true'>
                                Yes
                              </OptionButton>
                            </div>
                          </Field>

                          <Field label='Show date'>
                            <div className='option-button-group'>
                              <OptionButton {...register('site.theme.posts.showDate')} value='false'>
                                No
                              </OptionButton>
                              <OptionButton {...register('site.theme.posts.showDate')} value='true'>
                                Yes
                              </OptionButton>
                            </div>
                          </Field>

                          <div
                            className={classNames('stack -form', {
                              hidden: theme.posts.format !== 'grid'
                            })}
                          >
                            <Field
                              label='Text position'
                              htmlFor='site.theme.posts.grid.textPosition'
                              className={classNames({
                                hidden: theme.posts.showImage !== 'true'
                              })}
                            >
                              <div className='option-button-group'>
                                <OptionButton {...register('site.theme.posts.grid.textPosition')} value='below'>
                                  Below image
                                </OptionButton>

                                <OptionButton {...register('site.theme.posts.grid.textPosition')} value='over'>
                                  Over image
                                </OptionButton>
                              </div>
                            </Field>
                          </div>
                        </div>
                      )
                    },
                    {
                      name: 'Post',
                      content: (
                        <div className='stack -form'>
                          <Field label='Show date'>
                            <div className='option-button-group'>
                              <OptionButton {...register('site.theme.post.showDate')} value='false'>
                                No
                              </OptionButton>
                              <OptionButton {...register('site.theme.post.showDate')} value='true'>
                                Yes
                              </OptionButton>
                            </div>
                          </Field>

                          <Field label='Show author'>
                            <div className='option-button-group'>
                              <OptionButton {...register('site.theme.post.showAuthor')} value='false'>
                                No
                              </OptionButton>
                              <OptionButton {...register('site.theme.post.showAuthor')} value='true'>
                                Yes
                              </OptionButton>
                            </div>
                          </Field>
                        </div>
                      )
                    }
                  ]}
                />
              )
            },
            {
              name: 'Fonts',
              content: (
                <Accordion
                  activeItem={activeFontsItem}
                  onChange={setActiveFontsItem}
                  data={[
                    {
                      name: 'Base',
                      content: (
                        <>
                          <Field>
                            <div className='option-button-group -columns-4'>
                              {baseFonts.map(font => (
                                <OptionButton
                                  {...register('site.theme.fonts.base')}
                                  value={font.name}
                                  key={font.name}
                                  disabled={!pro && !font.free}
                                >
                                  <Font data={font} pro={pro} />
                                </OptionButton>
                              ))}
                            </div>
                          </Field>

                          {!pro && (
                            <p className='text -smaller mt-32'>
                              <a href='/settings/subscription'>Upgrade</a> to the <strong>Pro</strong> plan to enable premium fonts.
                            </p>
                          )}
                        </>
                      )
                    },
                    {
                      name: 'Text',
                      content: (
                        <Field>
                          <div className='option-button-group -columns-4'>
                            {textFonts.map(font => (
                              <OptionButton
                                {...register('site.theme.fonts.text')}
                                value={font.name}
                                key={font.name}
                                disabled={!pro && !font.free}
                              >
                                <Font data={font} pro={pro} />
                              </OptionButton>
                            ))}
                          </div>
                        </Field>
                      )
                    },
                    {
                      name: 'Headings',
                      content: (
                        <div className='stack -form'>
                          <Field>
                            <div className='option-button-group -columns-4'>
                              {headingFonts.map(font => (
                                <OptionButton
                                  {...register('site.theme.fonts.heading')}
                                  value={font.name}
                                  key={font.name}
                                  disabled={!pro && !font.free}
                                >
                                  <Font data={font} pro={pro} bold />
                                </OptionButton>
                              ))}
                            </div>
                          </Field>

                          <Field label='Weight'>
                            <div className='option-button-group -columns-4'>
                              {headingsFont.headingWeights.map(weight => (
                                <OptionButton {...register('site.theme.fonts.headingWeight')} value={`${weight}`} key={weight}>
                                  <div className='font'>
                                    <div className='font_preview' style={{ fontFamily: headingsFont.fontFamily, fontWeight: weight }}>
                                      Aa
                                    </div>
                                  </div>
                                </OptionButton>
                              ))}
                            </div>
                          </Field>
                        </div>
                      )
                    }
                  ]}
                />
              )
            },
            {
              name: 'Colors',
              content: (
                <Accordion
                  activeItem={activeColorsItem}
                  onChange={setActiveColorsItem}
                  data={[
                    {
                      name: 'Background',
                      content: (
                        <div className='px-4'>
                          <input type='hidden' {...register('site.theme.colors.background')} />
                          <input type='hidden' {...register('site.theme.colors.gray90')} />
                          <input type='hidden' {...register('site.theme.colors.gray80')} />
                          <input type='hidden' {...register('site.theme.colors.gray70')} />
                          <input type='hidden' {...register('site.theme.colors.gray60')} />
                          <input type='hidden' {...register('site.theme.colors.gray50')} />
                          <input type='hidden' {...register('site.theme.colors.gray40')} />
                          <input type='hidden' {...register('site.theme.colors.gray30')} />
                          <input type='hidden' {...register('site.theme.colors.gray20')} />
                          <input type='hidden' {...register('site.theme.colors.gray10')} />
                          <input type='hidden' {...register('site.theme.colors.gray05')} />

                          <div className='swatch-grid'>
                            {backgroundColors.map(color => (
                              <label
                                className={classNames('swatch', {
                                  '-active': theme.colors.backgroundSelection === color,
                                  '-disabled': color === 'custom' && !pro
                                })}
                                style={{ backgroundColor: color === 'custom' ? customBackgroundColor : color }}
                                key={color}
                              >
                                <input
                                  type='radio'
                                  {...register('site.theme.colors.backgroundSelection')}
                                  value={color}
                                  disabled={color === 'custom' && !pro}
                                />

                                {color === 'custom' && (
                                  <div className='swatch_content'>
                                    {!pro && (
                                      <span className='pro'>
                                        <StarIcon />
                                        Pro
                                      </span>
                                    )}

                                    <Dropdown
                                      isOpen={backgroundColorPickerOpen}
                                      onToggle={pro ? setBackgroundColorPickerOpen : () => {}}
                                      origin='top-left'
                                      renderToggle={(isOpen, onToggle) => (
                                        <div
                                          onClick={onToggle}
                                          className={classNames('swatch_custom', {
                                            '-dark': chroma(customBackgroundColor).luminance() < 0.6
                                          })}
                                        >
                                          {theme.colors.backgroundSelection === 'custom' && pro ? (
                                            <>
                                              <span>#</span>
                                              <HexColorInput
                                                type='text'
                                                className='swatch_input'
                                                color={customBackgroundColor}
                                                onChange={handleBackgroundColorChange}
                                              />
                                            </>
                                          ) : (
                                            <span>Custom</span>
                                          )}
                                        </div>
                                      )}
                                    >
                                      <div className='p-2'>
                                        <HexColorPicker color={customBackgroundColor} onChange={handleBackgroundColorChange} />
                                      </div>
                                    </Dropdown>
                                  </div>
                                )}
                              </label>
                            ))}
                          </div>

                          {!pro && (
                            <p className='text -smaller mt-40'>
                              <a href='/settings/subscription'>Upgrade</a> to the <strong>Pro</strong> plan to enable custom colors.
                            </p>
                          )}
                        </div>
                      )
                    },
                    {
                      name: 'Accent',
                      content: (
                        <div className='px-4'>
                          <input type='hidden' {...register('site.theme.colors.accent')} />
                          <input type='hidden' {...register('site.theme.colors.gray90')} />
                          <input type='hidden' {...register('site.theme.colors.gray80')} />
                          <input type='hidden' {...register('site.theme.colors.gray70')} />
                          <input type='hidden' {...register('site.theme.colors.gray60')} />
                          <input type='hidden' {...register('site.theme.colors.gray50')} />
                          <input type='hidden' {...register('site.theme.colors.gray40')} />
                          <input type='hidden' {...register('site.theme.colors.gray30')} />
                          <input type='hidden' {...register('site.theme.colors.gray20')} />
                          <input type='hidden' {...register('site.theme.colors.gray10')} />
                          <input type='hidden' {...register('site.theme.colors.gray05')} />

                          <div className='swatch-grid'>
                            {accentColors.map(color => (
                              <label
                                className={classNames('swatch', {
                                  '-active': theme.colors.accentSelection === color,
                                  '-disabled': color === 'custom' && !pro
                                })}
                                style={{ backgroundColor: color === 'custom' ? customAccentColor : color }}
                                key={color}
                              >
                                <input
                                  type='radio'
                                  {...register('site.theme.colors.accentSelection')}
                                  value={color}
                                  readOnly={color === 'custom' && !pro}
                                />

                                {color === 'custom' && (
                                  <div className='swatch_content'>
                                    {!pro && (
                                      <span className='pro'>
                                        <StarIcon />
                                        Pro
                                      </span>
                                    )}

                                    <Dropdown
                                      isOpen={accentColorPickerOpen}
                                      onToggle={pro ? setAccentColorPickerOpen : () => {}}
                                      origin='top-left'
                                      renderToggle={(isOpen, onToggle) => (
                                        <div
                                          onClick={onToggle}
                                          className={classNames('swatch_custom', {
                                            '-dark': chroma(customAccentColor).luminance() < 0.6
                                          })}
                                        >
                                          {theme.colors.accentSelection === 'custom' && pro ? (
                                            <>
                                              <span>#</span>
                                              <HexColorInput
                                                type='text'
                                                className='swatch_input'
                                                color={customAccentColor}
                                                onChange={handleAccentColorChange}
                                              />
                                            </>
                                          ) : (
                                            <span>Custom</span>
                                          )}
                                        </div>
                                      )}
                                    >
                                      <div className='p-2'>
                                        <HexColorPicker color={customAccentColor} onChange={handleAccentColorChange} />
                                      </div>
                                    </Dropdown>
                                  </div>
                                )}
                              </label>
                            ))}
                          </div>

                          {!pro && (
                            <p className='text -smaller mt-40'>
                              <a href='/settings/subscription'>Upgrade</a> to the <strong>Pro</strong> plan to enable custom colors.
                            </p>
                          )}
                        </div>
                      )
                    },
                    {
                      name: 'Text',
                      content: (
                        <div className='px-4'>
                          <input type='hidden' {...register('site.theme.colors.text')} />
                          <input type='hidden' {...register('site.theme.colors.textLight')} />
                          <input type='hidden' {...register('site.theme.colors.textLighter')} />

                          <div className='swatch-grid'>
                            {textColors.map(color => (
                              <label
                                className={classNames('swatch', {
                                  '-active': theme.colors.textSelection === color,
                                  '-disabled': color === 'custom' && !pro
                                })}
                                style={{ backgroundColor: textColorMap[color] }}
                                key={color}
                              >
                                <input
                                  type='radio'
                                  {...register('site.theme.colors.textSelection')}
                                  value={color}
                                  disabled={color === 'custom' && !pro}
                                />

                                {color === 'custom' && (
                                  <div className='swatch_content'>
                                    {!pro && (
                                      <span className='pro'>
                                        <StarIcon />
                                        Pro
                                      </span>
                                    )}

                                    <Dropdown
                                      isOpen={textColorPickerOpen}
                                      onToggle={pro ? setTextColorPickerOpen : () => {}}
                                      origin='top-left'
                                      renderToggle={(isOpen, onToggle) => (
                                        <div
                                          onClick={onToggle}
                                          className={classNames('swatch_custom', {
                                            '-dark': chroma(customTextColor).luminance() < 0.6
                                          })}
                                        >
                                          {theme.colors.textSelection === 'custom' ? (
                                            <>
                                              <span>#</span>
                                              <HexColorInput
                                                type='text'
                                                className='swatch_input'
                                                color={customTextColor}
                                                onChange={handleTextColorChange}
                                              />
                                            </>
                                          ) : (
                                            <span>Custom</span>
                                          )}
                                        </div>
                                      )}
                                    >
                                      <div className='p-2'>
                                        <HexColorPicker color={customTextColor} onChange={handleTextColorChange} />
                                      </div>
                                    </Dropdown>
                                  </div>
                                )}
                              </label>
                            ))}
                          </div>

                          {!pro && (
                            <p className='text -smaller mt-40'>
                              <a href='/settings/subscription'>Upgrade</a> to the <strong>Pro</strong> plan to enable custom colors.
                            </p>
                          )}
                        </div>
                      )
                    }
                  ]}
                />
              )
            }
          ]}
        />

        <div className='design-page_save'>
          <input
            type='submit'
            value={isEqual(savedForm, form) ? 'Published' : 'Publish'}
            className='button -wide'
            disabled={isEqual(savedForm, form)}
          />
        </div>
      </form>

      <div
        className={classNames('design-page_preview', {
          '-fullscreen': isFullscreen
        })}
      >
        <div className={classNames('design-page_preview-bar')}>
          {/* <Dropdown
            isOpen={true}
            onToggle={() => {}}
            origin='top-left'
            renderToggle={(isOpen, onToggle) => (
              <button className='dropdown_toggle text -smaller pl-4' onClick={onToggle}>
                Home
              </button>
            )}
          >
            <Menu>
              <button className='menu_item' onClick={() => {}}>
                Home
              </button>
            </Menu>
          </Dropdown> */}
          <select className='select -smaller -minimal' {...register(`currentPage`)}>
            <option value='home'>Home</option>
            {form.site.custom_home_page === 'true' && <option value='blog'>Blog</option>}
            <option value='post'>Post</option>
          </select>

          <button className='button -smaller -minimal -square design-page_maximize-button' onClick={() => setIsFullscreen(!isFullscreen)}>
            {isFullscreen ? <MinimizeIcon /> : <MaximizeIcon />}
          </button>
        </div>

        {renderSite()}
      </div>
    </div>
  )
}

const ImageField = ({ name, label, help, imageClass, onRemove, url, disabled, register }) => {
  return (
    <div className='field'>
      <label htmlFor={name} className='field_label'>
        {label}
      </label>

      <div className='flex -align-center mt-4'>
        {url && (
          <span className={imageClass}>
            <img src={url} />
          </span>
        )}
      </div>

      <label className='button -secondary -smaller mt-4' disabled={disabled}>
        <input type='file' id={name} accept='.png,.jpeg,.jpg,.gif' {...register(name)} disabled={disabled} />
        Choose file
      </label>

      {url && (
        <a className='button -smaller -destructive ml-8' onClick={onRemove}>
          Remove
        </a>
      )}

      <p className='text -smaller -light mt-8'>{help}</p>
    </div>
  )
}
const Font = ({ data, pro, bold }) => (
  <>
    {!pro && !data.free && (
      <span className='pro'>
        <StarIcon />
        Pro
      </span>
    )}
    <div className={classNames('font', { '-disabled': !pro && !data.free })}>
      <div className='font_preview' style={{ fontFamily: data.fontFamily, fontSize: 47 + data.offset, fontWeight: bold ? 700 : 400 }}>
        Aa
      </div>
      <div className='font_name' title={data.name}>
        {data.name}
      </div>
    </div>
  </>
)
