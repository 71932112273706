import React from 'react'
import classNames from 'classnames'
import { format, parseISO } from 'date-fns'

const Posts = ({ site, posts }) => {
  const { theme } = site

  return (
    <div
      className={classNames(`site-collection -${theme.posts.format}`, {
        '-inset': theme.posts.format === 'grid' && theme.posts.showImage === 'true' && theme.posts.grid.textPosition === 'over',
        '-columns-2': theme.posts.format === 'grid' && theme.posts.grid.columns === '2'
      })}
    >
      {posts.map((post, index) =>
        theme.posts.format === 'feed' ? (
          <article className='site-collection_item document' key={index}>
            <div className='container -max-8 mb-12'>
              <h1>
                <a>{post.title}</a>
              </h1>

              {(theme.posts.showDate === 'true' || theme.posts.showAuthor === 'true') && (
                <div className='site-collection_meta -inline mt-16 mb-24'>
                  {post.author?.name && post.author?.avatar_url && theme.posts.showAuthor === 'true' && (
                    <a className='site-collection_avatar -small'>{<img src={post.author.avatar_url} alt={post.author.name} />}</a>
                  )}

                  {post.author?.name && theme.posts.showAuthor === 'true' && (
                    <a className='site-collection_author mr-16'>{post.author.name}</a>
                  )}

                  {theme.posts.showDate === 'true' && <time>{format(parseISO(post.published_at), 'MMM d')}</time>}
                </div>
              )}
            </div>

            <div
              className='document_content'
              dangerouslySetInnerHTML={{
                __html: theme.posts.feed.truncate === 'true' ? post.truncated_html : post.html
              }}
            />

            {theme.posts.feed.truncate === 'true' ? (
              post.truncated_html.length < post.html.length && (
                <div className='container -max-8'>
                  <a className='site-collection_more'>Read more</a>
                </div>
              )
            ) : (
              <aside className='document_meta'>
                <div className='flex -align-center -wrap'>
                  {post.tags.map(tag => (
                    <a className='tag' key={tag.slug}>
                      {tag.name}
                    </a>
                  ))}
                </div>
              </aside>
            )}
          </article>
        ) : (
          <article className='site-collection_item' key={index}>
            {post.image_url && theme.posts.showImage === 'true' && (
              <a className='site-collection_image' style={{ backgroundImage: `url(${post.image_url}?size=small)` }}></a>
            )}

            <div className='site-collection_text'>
              <a>
                <h2 className='site-collection_title'>{post.title}</h2>
                {theme.posts.showExcerpt === 'true' && <div className='site-collection_excerpt'>{post.excerpt}</div>}
              </a>

              {(theme.posts.showDate === 'true' || theme.posts.showAuthor === 'true') && (
                <div className={`site-collection_meta ${post.author?.avatar_url ? 'mt-16' : 'mt-8'}`}>
                  {post.author?.name && post.author?.avatar_url && theme.posts.showAuthor === 'true' && (
                    <a className='site-collection_avatar'>{<img src={post.author.avatar_url} alt={post.author.name} />}</a>
                  )}

                  <div>
                    {post.author?.name && theme.posts.showAuthor === 'true' && (
                      <a className='site-collection_author mb-4 pb-2'>{post.author.name}</a>
                    )}

                    {theme.posts.showDate === 'true' && <time>{format(parseISO(post.published_at), 'MMM d')}</time>}
                  </div>
                </div>
              )}
            </div>
          </article>
        )
      )}
    </div>
  )
}

export default Posts
