const languages = [
  {
    key: 'c',
    name: 'C'
  },
  {
    key: 'clojure',
    name: 'Clojure'
  },
  {
    key: 'coffeescript',
    name: 'CoffeeScript'
  },
  {
    key: 'cpp',
    name: 'C++'
  },
  {
    key: 'csharp',
    name: 'C#'
  },
  {
    key: 'css',
    name: 'CSS'
  },
  {
    key: 'dart',
    name: 'Dart'
  },
  {
    key: 'diff',
    name: 'Diff'
  },
  {
    key: 'docker',
    name: 'Docker'
  },
  {
    key: 'elixir',
    name: 'Elixir'
  },
  {
    key: 'elm',
    name: 'Elm'
  },
  {
    key: 'erb',
    name: 'ERB'
  },
  {
    key: 'erlang',
    name: 'Erlang'
  },
  {
    key: 'fortran',
    name: 'Fortran'
  },
  {
    key: 'fsharp',
    name: 'F#'
  },
  {
    key: 'go',
    name: 'Go'
  },
  {
    key: 'graphql',
    name: 'GraphQL'
  },
  {
    key: 'haskell',
    name: 'Haskell'
  },
  {
    key: 'html',
    name: 'HTML'
  },
  {
    key: 'java',
    name: 'Java'
  },
  {
    key: 'javascript',
    name: 'JavaScript'
  },
  {
    key: 'json',
    name: 'JSON'
  },
  {
    key: 'jsx',
    name: 'JSX'
  },
  {
    key: 'kotlin',
    name: 'Kotlin'
  },
  {
    key: 'liquid',
    name: 'Liquid'
  },
  {
    key: 'Lua',
    name: 'Lua'
  },
  {
    key: 'make',
    name: 'Make'
  },
  {
    key: 'markdown',
    name: 'Markdown'
  },
  {
    key: 'objective_c',
    name: 'Objective C'
  },
  {
    key: 'ocaml',
    name: 'OCaml'
  },
  {
    key: 'pascal',
    name: 'Pascal'
  },
  {
    key: 'perl',
    name: 'Perl'
  },
  {
    key: 'php',
    name: 'PHP'
  },
  {
    key: 'plaintext',
    name: 'Plain text'
  },
  {
    key: 'prolog',
    name: 'Prolog'
  },
  {
    key: 'python',
    name: 'Python'
  },
  {
    key: 'q',
    name: 'Q'
  },
  {
    key: 'r',
    name: 'R'
  },
  {
    key: 'reasonml',
    name: 'Reason'
  },
  {
    key: 'ruby',
    name: 'Ruby'
  },
  {
    key: 'rust',
    name: 'Rust'
  },
  {
    key: 'sass',
    name: 'SASS'
  },
  {
    key: 'scala',
    name: 'Scala'
  },
  {
    key: 'scheme',
    name: 'Scheme'
  },
  {
    key: 'scss',
    name: 'SCSS'
  },
  {
    key: 'shell',
    name: 'Shell'
  },
  {
    key: 'sql',
    name: 'SQL'
  },
  {
    key: 'swift',
    name: 'Swift'
  },
  {
    key: 'typescript',
    name: 'TypeScript'
  },
  {
    key: 'vb',
    name: 'Visual Basic'
  },
  {
    key: 'xml',
    name: 'XML'
  },
  {
    key: 'yaml',
    name: 'YAML'
  }
]

export default languages
