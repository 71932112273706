import React from 'react'
import classNames from 'classnames'

import Header from './Header'
import Footer from './Footer'
import Posts from './Posts'
import Theme from './Theme'

const simpleFormat = text => text.split('\n\n').map((paragraph, index) => <p key={index}>{paragraph}</p>)

const Blog = ({ site, posts }) => {
  const { theme } = site

  return (
    <div className='body site wrapper'>
      <Theme data={site.theme} />
      <Header site={site} />

      {(site.blog_heading || site.blog_description) && (
        <div className='container'>
          <div
            className={classNames('site-hero', {
              '-left': theme?.home?.alignment === 'left',
              'ml-0': theme?.home?.alignment === 'left' && theme?.posts.format === 'grid'
            })}
          >
            {site.blog_heading && <h1>{site.blog_heading}</h1>}
            {site.blog_description && (
              <div className='site-hero_description'>{simpleFormat(site.blog_description)}</div>
            )}
          </div>
        </div>
      )}

      {posts.length > 0 && <Posts site={site} posts={posts} />}

      <Footer site={site} showLinksInHero={false} />
    </div>
  )
}

export default Blog
