import React, { useEffect, useState } from 'react'
import classNames from 'classnames'

export default function Tabs({ data, active, onChange }) {
  return (
    <div>
      <nav className='tabs'>
        {data.map((item, index) => (
          <a
            key={index}
            className={classNames('tabs_item', { '-active': index === active })}
            onClick={() => {
              onChange(index)
            }}
          >
            {item.name}
          </a>
        ))}
      </nav>

      {data.map((item, index) => (
        <div key={index} className={classNames({ hidden: index !== active })}>
          {item.content}
        </div>
      ))}
    </div>
  )
}
