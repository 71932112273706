import 'jquery-validation'
import 'jquery-smooth-scroll'
import autosize from 'autosize'
import Sortable from 'sortablejs'

// jQuery Validation messages
$.extend($.validator.messages, {
  required: 'Required',
  remote: 'Not valid',
  email: 'Not a valid format',
  url: 'Please enter a valid URL',
  date: 'Please enter a valid date',
  dateISO: 'Please enter a valid date (ISO)',
  number: 'Please enter a valid number',
  digits: 'Please enter only digits',
  equalTo: 'Please enter the same value again',
  minlength: $.validator.format('Too short (minimum is {0} characters)'),
  maxlength: $.validator.format('Too long (maximum is {0} characters)'),
  rangelength: $.validator.format('Please enter a value between {0} and {1} characters long'),
  range: $.validator.format('Please enter a value between {0} and {1}'),
  max: $.validator.format('Please enter a value less than or equal to {0}'),
  min: $.validator.format('Please enter a value greater than or equal to {0}'),
  step: $.validator.format('Please enter a multiple of {0}')
})

document.addEventListener('turbolinks:load', function () {
  // Fields with prefix/suffix
  $('.field_prefix').each(function () {
    var padding = 16 + $(this).outerWidth()
    $(this).next().css('padding-left', padding)
  })

  $('.field_suffix').each(function () {
    var padding = 16 + $(this).outerWidth()
    $(this).next().css('padding-right', padding)
  })

  // jQuery Validation call
  $('.js-validate').validate({
    errorClass: 'field_error',
    errorElement: 'span',
    highlight: function (element) {
      $(element).addClass('-error')
    },
    unhighlight: function (element) {
      $(element).removeClass('-error')
      $(element).next('.field_error').remove()
    }
  })

  // Flash
  setTimeout(function () {
    $('.flash').addClass('-show')
  }, 300)

  setTimeout(function () {
    $('.flash').removeClass('-show')
  }, 5000)

  autosize($('textarea'))

  // Sortable
  const $sortable = $('.js-sortable')

  if ($sortable.length) {
    $sortable.each((index, element) => {
      Sortable.create(element, {
        onUpdate: updatePositions,
        dragClass: 'sortable-drag',
        filter: 'input, select, a',
        preventOnFilter: false
      })
    })
  }

  // Checkboxes & radio buttons
  // updateInputLabels()

  // Hero signup button
  $('a').smoothScroll({
    offset: -60,
    speed: 800
  })

  const dropdowns = document.querySelectorAll('[data-toggle-dropdown]')

  if (dropdowns.length) {
    dropdowns.forEach(button => {
      const dropdown = document.getElementById(button.dataset.toggleDropdown)

      button.addEventListener('click', () => {
        dropdown.classList.toggle('-open')
      })

      document.addEventListener('click', event => {
        if (dropdown.classList.contains('-open') && !dropdown.contains(event.target) && !button.contains(event.target)) {
          dropdown.classList.remove('-open')
        }
      })
    })
  }

  const screenshots = document.querySelectorAll('.screenshot.-gallery img')

  if (screenshots.length) {
    const toggleScreenshot = index => {
      screenshots.forEach(screenshot => {
        screenshot.style.opacity = 0
      })

      screenshots[index].style.opacity = 1

      setTimeout(() => {
        toggleScreenshot(index + 1 < screenshots.length ? index + 1 : 0)
      }, 5000)
    }

    toggleScreenshot(0)
  }
})

document.addEventListener('turbolinks:before-cache', function () {
  $('.navigation.-open').removeClass('-open')
  $('.navigation-toggle.-active').removeClass('-active')
})

$(document).on('click', '.js-unhide', function (event) {
  $('.hidden').removeClass('hidden')
  $(this).addClass('hidden')
  event.preventDefault()
})

$(document).on('click', '.js-navigation-toggle', function (event) {
  document.querySelector('.navigation').classList.toggle('-open')
  this.classList.toggle('-active')
})

const onUnload = function () {
  return $('.editor_status').text() === 'Saving...' ? confirm('Wait! Your latest changes haven’t been saved.') : null
}

window.onbeforeunload = onUnload
$(document).on('turbolinks:before-visit', onUnload)

// Checkboxes & radio buttons
// $(document).on('change', 'input[type=radio], input[type=checkbox]', function(event) {
//   updateInputLabels()
// })

// function updateInputLabels() {
//   $('input[type=radio], input[type=checkbox]').parent().removeClass('-checked')
//   $('input[type=radio]:checked, input[type=checkbox]:checked').parent().addClass('-checked')
// }

// Sortable
function updatePositions() {
  $('.js-sortable')
    .find('.js-position')
    .each(function (index, item) {
      $(item).val(index)
    })
}

// Nested records
$(document).on('click', '.js-add-nested-record', function (event) {
  $(this).prev().find('.js-nested-record.hidden').first().removeClass('hidden').appendTo($(this).prev())
  updatePositions()
  event.preventDefault()
})

$(document).on('click', '.js-remove-nested-record', function (event) {
  const $record = $(this).prev().closest('.js-nested-record')
  $record.addClass('hidden').appendTo($record.parent()).find('input, select').val('')
  updatePositions()
  event.preventDefault()
})

$(document).on('click', '[data-remove-image]', function () {
  $(this).closest('.field').find('input[type=hidden]').val(true)
  $(this).addClass('hidden')
  $(this).closest('.field').find('img').parent().addClass('hidden')
})

$(document).on('change', '[data-image-preview]', function () {
  const target = $(this).data('image-preview')

  if (this.files && this.files[0]) {
    const reader = new FileReader()

    reader.onload = function (event) {
      const image = document.getElementById(target)
      image.setAttribute('src', event.target.result)
      image.parentElement.classList.remove('hidden')
    }

    reader.readAsDataURL(this.files[0])
  }
})

$(document).on('click', '.js-copy', function(event) {
  const button = $(this)
  const input = $(this).prev()[0]
  input.select()
  input.setSelectionRange(0, 99999)
  document.execCommand('copy')
  button.text('Copied!').attr('disabled', true)

  setTimeout(() => {
    button.text('Copy').attr('disabled', false)
  }, 1000)

  event.preventDefault()
})

$(window).on('scroll', function () {
  const $header = $('.header')

  if (window.scrollY > 24 && !$header.hasClass('-sticky')) {
    $header.addClass('-sticky')
  } else if (window.scrollY <= 24 && $header.hasClass('-sticky')) {
    $header.removeClass('-sticky')
  }
})
