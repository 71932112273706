import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import ChevronDownIcon from 'icons/chevron-down.svg'

export default function Accordion({ data, onChange, activeItem }) {
  return (
    <div className='accordion'>
      {data.map((item, index) => (
        <AccordionItem
          key={index}
          data={item}
          active={index === activeItem}
          onClick={() => {
            onChange(index === activeItem ? null : index)
          }}
        />
      ))}
    </div>
  )
}

const AccordionItem = ({ data, active, onClick }) => {
  const ref = useRef()
  const timeoutRef = useRef()

  useLayoutEffect(() => {
    const element = ref.current
    clearTimeout(timeoutRef.current)

    if (active) {
      element.style.height = 'auto'
      let height = element.offsetHeight

      if (height === 0) {
        const clone = element.cloneNode(true)
        document.body.appendChild(clone)
        height = clone.offsetHeight
        clone.remove()
      }

      element.style.height = 0
      element.offsetHeight
      element.style.height = `${height}px`

      timeoutRef.current = setTimeout(() => {
        element.style.overflow = `visible`
      }, 400)
    } else {
      element.style.overflow = `hidden`
      element.style.height = 0
    }
  }, [active])

  useEffect(() => {
    const element = ref.current

    if (active && element.offsetHeight > 0) {
      element.style.height = 'auto'
      const height = element.offsetHeight
      element.style.height = `${height}px`
    }
  }, [data.content])

  return (
    <div className={classNames('accordion_item', { '-active': active })}>
      <a className={'accordion_header'} onClick={onClick}>
        {data.name}
        <ChevronDownIcon />
      </a>

      <div key={data.name} className='accordion_content' ref={ref}>
        <div className='pt-8 pb-32'>{data.content}</div>
      </div>
    </div>
  )
}
