import React from 'react'
import classNames from 'classnames'
import useOutsideClickHandler from './useOutsideClickHandler'

export default function Dropdown({ children, renderToggle, isOpen, wide, onToggle, origin = 'top-right'}) {
  const [ref] = useOutsideClickHandler(() => {
    if (isOpen) {
      onToggle(false)
    }
  })

  return (
    <div className={classNames(`dropdown`, { '-open': isOpen })} ref={ref}>
      {renderToggle && renderToggle(isOpen, onToggle)}

      <div className={classNames(`dropdown_content -${origin}`, { '-open': isOpen, '-wide': wide })}>
        {children}
      </div>
    </div>
  )
}
