import { find } from 'lodash'
import React from 'react'
import fonts from '../fonts'

const Theme = ({ data }) => {
  const baseFont = find(fonts, { name: data.fonts.base })
  const textFont = find(fonts, { name: data.fonts.text })
  const headingsFont = find(fonts, { name: data.fonts.heading })

  if (!baseFont) return null

  return (
    <style>
      {`.site {
        --font-family-base: ${baseFont.fontFamily};
        --font-family-text: ${textFont.fontFamily};
        --font-family-heading: ${headingsFont.fontFamily};
        --font-size-base-offset: ${baseFont.offset}px;
        --font-size-text-offset: ${textFont.offset}px;
        --font-size-heading-offset: ${headingsFont.offset}px;
        --font-weight-heading: ${data.fonts.headingWeight};
        --line-height-base: ${1.55 - 0.05 * baseFont.offset};
        --line-height-text: ${1.75 - 0.05 * textFont.offset};
        --line-height-heading: ${1.25 - 0.05 * headingsFont.offset};
        ${headingsFont.headingOnly ? '--letter-spacing-heading: 0;': ''}

        --color-gray-90: ${data.colors.gray90};
        --color-gray-80: ${data.colors.gray80};
        --color-gray-70: ${data.colors.gray70};
        --color-gray-60: ${data.colors.gray60};
        --color-gray-50: ${data.colors.gray50};
        --color-gray-40: ${data.colors.gray40};
        --color-gray-30: ${data.colors.gray30};
        --color-gray-20: ${data.colors.gray20};
        --color-gray-10: ${data.colors.gray10};
        --color-gray-05: ${data.colors.gray05};
        --color-background: ${data.colors.background};

        --color-primary-90: ${data.colors.accent90};
        --color-primary-80: ${data.colors.accent80};
        --color-primary-70: ${data.colors.accent70};
        --color-primary-60: ${data.colors.accent60};
        --color-primary-50: ${data.colors.accent50};
        --color-primary-40: ${data.colors.accent40};
        --color-primary-30: ${data.colors.accent30};
        --color-primary-20: ${data.colors.accent20};
        --color-primary-10: ${data.colors.accent10};

        --color-text: ${data.colors.text};
        --color-text-light: ${data.colors.textLight};
        --color-text-lighter: ${data.colors.textLighter};
      }

      @media (max-width: 728px) {
        .site {
            --font-size-base-offset: ${baseFont.offset - 1}px;
            --font-size-text-offset: ${textFont.offset - 1}px;
            --font-size-heading-offset: ${headingsFont.offset - 2}px;
          }
        }

        @media (max-width: 480px) {
        .site {
            --font-size-heading-offset: ${headingsFont.offset - 4}px;
          }
        }
      `}
    </style>
  )
}

export default Theme
