import React from 'react'
import classNames from 'classnames'

const Header = ({ site }) => {
  const internalLinks = site.internal_links_attributes.filter(item => item.text || item.icon)

  if (internalLinks.length === 0) {
    return null
  }

  return (
    <nav className='site-navigation'>
      {internalLinks.map((item, index) => (
        <a className='site-navigation_item' key={index}>
          {item.text}
        </a>
      ))}
    </nav>
  )
}

export default Header
