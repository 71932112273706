require('@rails/ujs').start()
require('turbolinks').start()
require('local-time').start()
require('@rails/activestorage').start()
require('src/application')
var componentRequireContext = require.context('components', true)
var ReactRailsUJS = require('react_ujs')
ReactRailsUJS.useContext(componentRequireContext);
ReactRailsUJS.removeEvent('turbolinks:before-render', ReactRailsUJS.handleMount)
ReactRailsUJS.handleEvent('turbolinks:before-render', ReactRailsUJS.handleUnmount)
