import React from 'react'
import { any, array, bool, func, string } from 'prop-types'
import classNames from 'classnames'

class Field extends React.Component {
  static propTypes = {
    name: string,
    value: any,
    className: string,
    autoComplete: string,
    placeholder: string,
    errors: array,
    label: string,
    help: string,
    disabled: bool,
    inline: bool,
    onChange: func,
    onFocus: func
  }

  static defaultProps = {
    className: ''
  }

  render() {
    const { className, label, name, value, disabled, placeholder, autoComplete, errors, small, onFocus, onChange, help } = this.props

    return (
      <div className={'field ' + className}>
        <label className='field_label' htmlFor={name}>{label}</label>

        <input
          type='text'
          id={name}
          name={name}
          onChange={onChange}
          onFocus={onFocus}
          value={value}
          autoComplete={autoComplete}
          disabled={disabled}
          placeholder={placeholder}
          className={classNames('input', { '-error': errors, '-small': small })}
        />

        {errors && <span className='field_error'>{errors[0]}</span>}
        {help && <span className='field_help'>{help}</span>}
      </div>
    )
  }
}

export default Field
