import React from 'react'
import { sumBy } from 'lodash'
import classNames from 'classnames'

import email from 'icons/email.svg'
import rss from 'icons/rss.svg'
import globe from 'icons/globe.svg'
import angellist from 'icons/angellist.svg'
import applepodcasts from 'icons/applepodcasts.svg'
import buymeacoffee from 'icons/buymeacoffee.svg'
import crunchbase from 'icons/crunchbase.svg'
import devto from 'icons/devto.svg'
import discord from 'icons/discord.svg'
import dribbble from 'icons/dribbble.svg'
import facebook from 'icons/facebook.svg'
import github from 'icons/github.svg'
import goodreads from 'icons/goodreads.svg'
import instagram from 'icons/instagram.svg'
import linkedin from 'icons/linkedin.svg'
import mastodon from 'icons/mastodon.svg'
import medium from 'icons/medium.svg'
import patreon from 'icons/patreon.svg'
import pinterest from 'icons/pinterest.svg'
import producthunt from 'icons/producthunt.svg'
import reddit from 'icons/reddit.svg'
import slack from 'icons/slack.svg'
import spotify from 'icons/spotify.svg'
import stackoverflow from 'icons/stackoverflow.svg'
import telegram from 'icons/telegram.svg'
import tripadvisor from 'icons/tripadvisor.svg'
import twitter from 'icons/twitter.svg'
import unsplash from 'icons/unsplash.svg'
import vsco from 'icons/vsco.svg'
import whatsapp from 'icons/whatsapp.svg'
import youtube from 'icons/youtube.svg'

const icons = {
  email: email,
  rss: rss,
  globe: globe,
  angellist: angellist,
  applepodcasts: applepodcasts,
  buymeacoffee: buymeacoffee,
  crunchbase: crunchbase,
  devto: devto,
  discord: discord,
  dribbble: dribbble,
  facebook: facebook,
  github: github,
  goodreads: goodreads,
  instagram: instagram,
  linkedin: linkedin,
  mastodon: mastodon,
  medium: medium,
  patreon: patreon,
  pinterest: pinterest,
  producthunt: producthunt,
  reddit: reddit,
  slack: slack,
  spotify: spotify,
  stackoverflow: stackoverflow,
  telegram: telegram,
  tripadvisor: tripadvisor,
  twitter: twitter,
  unsplash: unsplash,
  vsco: vsco,
  whatsapp: whatsapp,
  youtube: youtube
}

const Footer = ({ site, showLinksInHero }) => {
  const externalLinks = site.external_links_attributes.filter(item => item.text || item.icon)
  const internalLinks = site.internal_links_attributes.filter(item => item.text || item.icon)

  const renderExternalLinks = () => (
    <nav className='site-navigation mt-40'>
      {externalLinks.map((item, index) => {
        const Icon = icons[item.icon]

        return (
          <a className='site-navigation_item' key={index}>
            {item.icon && <Icon />}
            {item.text && <span>{item.text}</span>}
          </a>
        )
      })}
    </nav>
  )

  return (
    <footer
      className={classNames('container site-footer', {
        'mt-0': showLinksInHero,
        '-center': !site.proud && site.theme?.home?.alignment !== 'left',
        '-max-8': sumBy(internalLinks, link => link.text.length + 3) <= 48 && site.theme.posts.format !== 'grid' && !site.heading
      })}
    >
      <div className='container -max-4'>
        <div className='flex -center'>
          <input type='text' className='input -small mr-8' placeholder='Your email' />
          <input type='submit' value='Subscribe' className='button -small' />
        </div>
      </div>

      {externalLinks.length > 0 && renderExternalLinks()}

      {site.proud !== false && (
        <p className='text -center -small -light mt-64'>
          Made with{' '}
          <a href='https://bloggi.co' className='text -undecorated'>
            Bloggi
          </a>
        </p>
      )}
    </footer>
  )
}

export default Footer
