import React, { useEffect, useState } from 'react'
import classNames from 'classnames'

import useOutsideClickHandler from './useOutsideClickHandler'
import XIcon from 'icons/x.svg'
import { sortBy } from 'lodash'

export default function MultiSelect({ value: values, options, onChange }) {
  const [isOpen, setIsOpen] = useState(false)
  const [text, setText] = useState('')
  const [focusedItem, setFocusedItem] = useState(0)

  const [ref] = useOutsideClickHandler(() => {
    if (isOpen) {
      setIsOpen(false)
    }
  })

  const availableOptions = sortBy(
    options.filter(option => !values.includes(option.value) && (!text || option.value.toLowerCase().includes(text.toLowerCase()))),
    [option => option.label.toLowerCase()]
  )

  if (text && !values.filter(value => value.toLowerCase() === text.toLowerCase()).length) {
    availableOptions.push({ value: text, label: `Add “${text}”` })
  }

  useEffect(() => {
    if (focusedItem >= availableOptions.length) {
      setFocusedItem(0)
    }
  }, [availableOptions.length])

  return (
    <div className={classNames(`dropdown`, { '-open': isOpen })} ref={ref}>
      <label className={classNames('multi-select input -small', { '-focus': isOpen })} htmlFor='tags'>
        {values.map(value => (
          <button
            className='multi-select_value'
            type='button'
            tabIndex={-1}
            key={value}
            onClick={event => {
              onChange(values.filter(v => v !== value))
              event.preventDefault()
            }}
          >
            {value}
            <XIcon />
          </button>
        ))}

        <input
          type='text'
          size={2}
          id='tags'
          autoComplete='off'
          value={text}
          onChange={event => setText(event.target.value)}
          onFocus={() => {
            setIsOpen(true)
            setFocusedItem(0)
          }}
          onKeyDown={event => {
            if (availableOptions.length > 0) {
              if (event.key === 'ArrowDown') {
                setFocusedItem(focusedItem === availableOptions.length - 1 ? 0 : focusedItem + 1)
              } else if (event.key === 'ArrowUp') {
                setFocusedItem(focusedItem > 0 ? focusedItem - 1 : availableOptions.length - 1)
              } else if (event.key === 'Enter') {
                onChange([...values, availableOptions[focusedItem].value])
                setText('')
                setFocusedItem(0)
                event.preventDefault()
              }
            }

            if (event.key === 'Backspace' && !text) {
              onChange(values.slice(0, -1))
            } else if (event.key === 'Tab') {
              setIsOpen(false)
            }
          }}
        />
      </label>

      {availableOptions.length > 0 && (
        <div
          className={classNames('dropdown_content -top', { '-open': isOpen })}
          style={{ maxHeight: 224, overflowY: 'auto', width: '100%' }}
        >
          <div className='menu'>
            {availableOptions.map((option, index) => (
              <button
                key={option.label}
                className={classNames('menu_item', { '-focus': focusedItem === index })}
                type='button'
                tabIndex={-1}
                onClick={() => {
                  onChange([...values, option.value])
                  setIsOpen(false)
                  setText('')
                }}
              >
                {option.label}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}
