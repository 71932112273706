import React from 'react'
import classNames from 'classnames'

import Header from './Header'
import Navigation from './Navigation'
import Footer from './Footer'
import Posts from './Posts'
import Theme from './Theme'

const simpleFormat = text => text.split('\n\n').map((paragraph, index) => <p key={index}>{paragraph}</p>)

const Home = ({ site, posts, homePage }) => {
  const { theme } = site
  const isStandard = theme?.home?.format === 'standard' || ((theme?.home?.format === 'auto' || !theme?.home?.format) && site.heading)
  const isCompact = theme?.home?.format === 'compact' || !isStandard
  const showHero = site.avatar || site.heading || site.description || isCompact

  const hasCustomHomePage = site.custom_home_page === 'true'
  const showLinksInHero = isCompact && posts.length === 0 && !hasCustomHomePage

  return (
    <div className='body site wrapper'>
      <Theme data={theme} />
      {isStandard && <Header site={site} />}

      {showHero && (
        <div className='container'>
          <div
            className={classNames('site-hero', {
              'mb-24': showLinksInHero,
              '-left': theme?.home?.alignment === 'left',
              'ml-0': theme?.home?.alignment === 'left' && theme?.posts.format === 'grid' && !hasCustomHomePage
            })}
          >
            {site.avatar && <img src={site.avatar} className='site-hero_avatar' />}

            {isCompact &&
              (site.logo ? (
                <img className={classNames('site-hero_logo', { '-small': site.heading })} src={site.logo} alt={site.name} />
              ) : site.heading ? (
                <span className='site-logo'>{site.name}</span>
              ) : (
                <h1>{site.name}</h1>
              ))}

            {site.heading && <h1>{site.heading}</h1>}
            {site.description && <div className='site-hero_description'>{simpleFormat(site.description)}</div>}

            {isCompact && <Navigation site={site} />}
          </div>
        </div>
      )}

      {!hasCustomHomePage ? (
        posts.length > 0 && <Posts site={site} posts={posts} />
      ) : (
        <div className='site-collection -feed'>
          <article className='site-collection_item document'>
            <div
              className='document_content'
              dangerouslySetInnerHTML={{
                __html: (homePage.title ? `<h1>${homePage.title}</h1>` : '') + homePage.html
              }}
            />
          </article>
        </div>
      )}

      <Footer site={site} showLinksInHero={showLinksInHero} />
    </div>
  )
}

export default Home
