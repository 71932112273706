import React from 'react'
import { func, string, array, bool } from 'prop-types'
import { includes } from 'lodash'
import classNames from 'classnames'
import axios from 'axios'

const ENTER_KEY = 13
const LEFT_KEY = 37
const UP_KEY = 38
const RIGHT_KEY = 39
const DOWN_KEY = 40
const BACKSPACE_KEY = 8
const DELETE_KEY = 46

export default class MarkdownEditor extends React.Component {
  static propTypes = {
    title: string,
    text: array,
    onTitleChange: func,
    onTextChange: func
  }

  state = {
    dragging: 0,
    titleHeight: 'auto',
    textHeight: 'auto'
  }

  componentDidMount() {
    const title = document.getElementById('title')

    if (title) {
      title.focus()
    }

    this.updateHeight()
    window.addEventListener('resize', this.updateHeight, false)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateHeight, false)
  }

  handleTitleKeyDown = event => {
    const titleElement = document.getElementById('title')
    const textElement = document.getElementById('text')
    const { title } = this.props
    const text = this.props.text[0].children[0].text
    const position = titleElement.selectionStart

    if (event.which === ENTER_KEY) {
      this.props.onTitleChange(title.substring(0, position))
      this.handleTextChange(title.substring(position) ? title.substring(position) + '\n' + text : text, () => {
        textElement.selectionStart = textElement.selectionEnd = 0
        textElement.focus()
      })

      event.preventDefault()
    } else if (event.which === DELETE_KEY) {
      if (position === title.length) {
        this.props.onTitleChange(title + text.split('\n')[0])
        this.handleTextChange(text.substring(text.indexOf('\n') + 1), () => {
          titleElement.selectionStart = titleElement.selectionEnd = title.length
        })

        event.preventDefault()
      }
    } else if (event.which === DOWN_KEY) {
      textElement.selectionStart = textElement.selectionEnd = position
      textElement.focus()
      event.preventDefault()
    } else if (event.which === RIGHT_KEY) {
      if (position === title.length) {
        textElement.selectionStart = textElement.selectionEnd = 0
        textElement.focus()
        event.preventDefault()
      }
    }
  }

  handleTextKeyDown = event => {
    const titleElement = document.getElementById('title')
    const textElement = document.getElementById('text')
    const { title } = this.props
    const text = this.props.text[0].children[0].text
    const position = textElement.selectionStart

    if (event.which === BACKSPACE_KEY) {
      if (position === 0 && textElement.selectionEnd === 0) {
        this.props.onTitleChange(title + text.split('\n')[0])
        this.handleTextChange(text.indexOf('\n') === -1 ? '' : text.substring(text.indexOf('\n') + 1), () => {
          titleElement.selectionStart = titleElement.selectionEnd = title.length
          titleElement.focus()
        })
        event.preventDefault()
      }
    }

    if (event.which === UP_KEY) {
      if (position <= text.split('\n')[0].length) {
        titleElement.selectionStart = titleElement.selectionEnd = position
        titleElement.focus()
        event.preventDefault()
      }
    }

    if (event.which === LEFT_KEY) {
      if (position === 0) {
        titleElement.selectionStart = titleElement.selectionEnd = title.length
        titleElement.focus()
        event.preventDefault()
      }
    }
  }

  handleDragEnter = () => {
    this.setState({ dragging: this.state.dragging + 1 })
  }

  handleDragOver = event => {
    event.preventDefault()
  }

  handleDragLeave = () => {
    this.setState({ dragging: this.state.dragging - 1 })
  }

  handleDrop = event => {
    event.preventDefault()
    this.setState({ dragging: false })

    if (event.dataTransfer.items) {
      if (event.dataTransfer.items[0].kind === 'file') {
        this.uploadImages(Array.from(event.dataTransfer.items).map(item => item.getAsFile()))
      }
    } else {
      this.uploadImages(Array.from(event.dataTransfer.files))
    }
  }

  handlePaste = event => {
    if (event.clipboardData.items[0].kind === 'file') {
      this.uploadImages(Array.from(event.clipboardData.items).map(item => item.getAsFile()))
    }
  }

  uploadImages = files => {
    const images = files.filter(file => includes(['image/jpeg', 'image/png', 'image/gif'], file.type))
    const textElement = document.getElementById('text')
    const position = textElement.selectionStart
    const text = this.props.text[0].children[0].text
    const uploadingText = images.map(file => `![Uploading ${file.name}...]`).join('\n')
    let startText = text.substring(0, position)
    let endText = text.substring(position, text.length)

    if (position > 0 && startText.charAt(position - 1) !== '\n') {
      startText += '\n'
    }

    if (endText.charAt(0) !== '\n') {
      endText = '\n' + endText
    }

    this.handleTextChange(startText + uploadingText + endText)
    textElement.selectionStart = textElement.selectionEnd = position + uploadingText.length

    images.forEach(file => {
      let formData = new FormData()
      formData.set('image[file]', file)

      axios
        .post('/images', formData, {
          headers: {
            'content-type': 'multipart/form-data'
          }
        })
        .then(response => {
          const {
            data: {
              image: { url, name }
            }
          } = response
          const imageText = `![${name.split('.').slice(0, -1).join('.')}](${url})`
          const latestText = this.props.text[0].children[0].text

          this.handleTextChange(latestText.replace(`![Uploading ${file.name}...]`, imageText))
          textElement.selectionStart = textElement.selectionEnd = latestText.indexOf(imageText) + imageText.length
        })
        .catch(function (error) {
          console.log(error)
        })
    })
  }

  updateHeight = () => {
    window.removeEventListener('scroll', this.handleScroll)
    const position = window.scrollY
    const title = document.getElementById('title')

    this.setState(
      {
        titleHeight: 'auto',
        textHeight: 'auto'
      },
      () => {
        this.setState(
          {
            titleHeight: title && title.scrollHeight,
            textHeight: document.getElementById('text').scrollHeight
          },
          () => {
            // This maintains the scroll position in the same place
            window.scrollTo(0, position)

            setTimeout(() => {
              window.addEventListener('scroll', this.handleScroll)
            }, 10)
          }
        )
      }
    )
  }

  handleTextChange = (value, callback) => {
    this.props.onTextChange([
      {
        type: 'markdown',
        children: [{ text: value }]
      }
    ], callback)

    this.updateHeight()
  }

  render() {
    return (
      <div
        className={classNames('editor_content', {
          '-dragging': this.state.dragging
        })}
        onPaste={this.handlePaste}
        onDrop={this.handleDrop}
        onDragOver={this.handleDragOver}
        onDragStart={this.handleDragStart}
        onDragEnter={this.handleDragEnter}
        onDragLeave={this.handleDragLeave}
      >
        <div className='editor_text'>
          <div className='editor_title'>
            <textarea
              id='title'
              placeholder='Title'
              autoComplete='off'
              value={this.props.title}
              onChange={event => {
                this.props.onTitleChange(event.target.value)
                this.updateHeight()
              }}
              onKeyDown={this.handleTitleKeyDown}
              style={{ height: this.state.titleHeight }}
              rows='1'
            />
          </div>

          <textarea
            id='text'
            placeholder='Start writing...'
            value={this.props.text[0].children[0].text}
            onChange={event => {
              this.handleTextChange(event.target.value)
            }}
            onKeyDown={this.handleTextKeyDown}
            style={{ height: this.state.textHeight }}
          />
        </div>
      </div>
    )
  }
}
