import React from 'react'
import classNames from 'classnames'
import ExpandingTextarea from 'react-expanding-textarea'

const Textarea = React.forwardRef(({ error, small, ...otherProps }, ref) => (
  <ExpandingTextarea
    type='text'
    ref={ref}
    className={classNames('textarea', { '-error': error, '-small': small })}
    {...otherProps}
  />
))

export default Textarea
