import React from 'react'
import classNames from 'classnames'

const Option = React.forwardRef(({ children, type, box, disabled, ...otherProps }, ref) => (
  <label className={classNames('option', { '-box': box, '-disabled': disabled })}>
    <input type={type} ref={ref} {...otherProps} />
    <span className='option_control'></span>
    {children}
  </label>
))

export default Option
