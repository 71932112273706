const fonts = [
  {
    name: 'Source Sans',
    fontFamily: 'Source Sans, sans-serif',
    headingWeights: [600, 700, 900],
    offset: 1,
    headingOnly: false,
    free: true,
    matches: ['Source Serif', 'FF Meta Serif'],
    headingMatches: ['Source Serif', 'IvyPresto Display']
  },
  //   {
  //   name: 'IBM Plex Sans',
  //   fontFamily: 'IBM Plex Sans, sans-serif',
  //   headingWeights: [600, 700, 900],
  //   offset: 0,
  //   headingOnly: false,
  //   free: false,
  //   matches: ['IBM Plex Serif'],
  // headingMatches: []
  // },
  // {
  //   name: 'IBM Plex Serif',
  //   fontFamily: 'IBM Plex Serif, sans-serif',
  //   headingWeights: [600, 700, 900],
  //   offset: 0,
  //   headingOnly: false,
  //   free: false,
  //   matches: ['IBM Plex Sans'],
  // headingMatches: []
  // },
  {
    name: 'Calluna Sans',
    fontFamily: 'calluna-sans, sans-serif',
    headingWeights: [700, 900],
    offset: 1,
    headingOnly: false,
    free: false,
    matches: ['Calluna'],
    headingMatches: [
      'Calluna',
      'Freight Display',
      'Orpheus Pro',
      'Sofia',
      'Sofia Soft',
      'Brandon Grotesque',
      'Granville'
    ]
  },
  {
    name: 'Freight Sans',
    fontFamily: 'freight-sans-pro, sans-serif',
    headingWeights: [600, 700],
    offset: 1,
    headingOnly: false,
    free: false,
    matches: ['Freight Text'],
    headingMatches: [
      'Freight Text',
      'Freight Display',
      'Orpheus Pro',
      'Sofia',
      'Sofia Soft',
      'Granville'
    ]
  },
  {
    name: 'Adelle Sans',
    fontFamily: 'adelle-sans, sans-serif',
    headingWeights: [700, 800],
    offset: 0,
    headingOnly: false,
    free: false,
    matches: ['Adelle', 'FF Meta Serif'],
    headingMatches: ['Adelle', 'IvyPresto Display']
  },
  // {
  //   name: 'Questa Sans',
  //   fontFamily: 'questa-sans, sans-serif',
  //   headingWeights: [700],
  //   offset: 1,
  //   headingOnly: false,
  //   free: false,
  //   matches: ['Questa'],
  // headingMatches: []
  // },

  {
    name: 'FF Meta',
    fontFamily: 'ff-meta-web-pro, sans-serif',
    headingWeights: [700, 800],
    offset: 1,
    headingOnly: false,
    free: false,
    matches: ['FF Meta Serif', 'Adelle'],
    headingMatches: ['FF Meta Serif', 'Adelle', 'IvyPresto Display']
  },

  {
    name: 'Acumin',
    fontFamily: 'acumin-pro, sans-serif',
    headingWeights: [700, 800],
    offset: 0,
    headingOnly: false,
    free: false,
    matches: ['Source Serif', 'FF Meta Serif', 'Adelle'],
    headingMatches: ['Adelle', 'IvyPresto Display', 'Orpheus Pro']
  },

  // {
  //   name: 'Degular',
  //   fontFamily: 'degular, sans-serif',
  //   headingWeights: [500, 600, 700, 800],
  //   offset: 1,
  //   headingOnly: false,
  //   free: false,
  //   matches: [],
  // headingMatches: []
  // },

  {
    name: 'Futura',
    fontFamily: 'futura-pt, sans-serif',
    headingWeights: [600, 700],
    offset: 2,
    headingOnly: false,
    free: false,
    matches: ['Calluna'],
    headingMatches: ['Brandon Grotesque', 'Sofia Soft', 'Granville', 'Orpheus Pro']
  },
  // {
  //   name: 'Proxima Nova',
  //   fontFamily: 'proxima-nova, sans-serif',
  //   headingWeights: [500, 700],
  //   offset: 0,
  //   headingOnly: false,
  //   free: false,
  //   matches: [],
  // headingMatches: []
  // },
  {
    name: 'Brandon Grotesque',
    fontFamily: 'brandon-grotesque, sans-serif',
    headingWeights: [700, 900],
    offset: 2,
    headingOnly: false,
    free: false,
    matches: ['Calluna', 'Freight Text'],
    headingMatches: ['Sofia Soft', 'Granville', 'Orpheus Pro']
  },
  {
    name: 'Sofia',
    fontFamily: 'sofia-pro, sans-serif',
    headingWeights: [500, 600, 700, 900],
    offset: 0,
    headingOnly: false,
    free: false,
    matches: ['Calluna', 'Freight Text'],
    headingMatches: ['Brandon Grotesque', 'Sofia Soft', 'Granville', 'Freight Display', 'Orpheus Pro']
  },

  {
    name: 'Sofia Soft',
    fontFamily: 'sofia-pro-soft, sans-serif',
    headingWeights: [500, 700],
    offset: 0,
    headingOnly: false,
    free: false,
    matches: [],
    headingMatches: ['Granville', 'Freight Display']
  },

  {
    name: 'Granville',
    fontFamily: 'granville, sans-serif',
    headingWeights: [700],
    offset: 1,
    headingOnly: false,
    free: false,
    matches: ['Freight Text'],
    headingMatches: ['Orpheus Pro', 'Brandon Grotesque', 'Sofia Soft']
  },
  {
    name: 'Source Serif',
    fontFamily: 'Source Serif, serif',
    headingWeights: [500, 700, 900],
    offset: 1,
    headingOnly: false,
    free: true,
    matches: ['Source Sans'],
    headingMatches: ['Source Sans', 'IvyPresto Display']
  },

  {
    name: 'Calluna',
    fontFamily: 'calluna, serif',
    headingWeights: [600, 700, 900],
    offset: 1,
    headingOnly: false,
    free: false,
    matches: ['Calluna Sans'],
    headingMatches: ['Calluna Sans', 'Freight Display', 'Orpheus Pro']
  },

  {
    name: 'Freight Text',
    fontFamily: 'freight-text-pro, serif',
    headingWeights: [500, 600, 700],
    offset: 1,
    headingOnly: false,
    free: false,
    matches: ['Freight Sans'],
    headingMatches: ['Freight Sans', 'Freight Display', 'Orpheus Pro']
  },

  {
    name: 'Adelle',
    fontFamily: 'adelle, serif',
    headingWeights: [600, 700, 800],
    offset: 0,
    headingOnly: false,
    free: false,
    matches: ['Adelle Sans'],
    headingMatches: ['Adelle Sans', 'IvyPresto Display']
  },

  // {
  //   name: 'Questa',
  //   fontFamily: 'questa, serif',
  //   headingWeights: [700],
  //   offset: 1,
  //   headingOnly: false,
  //   free: false,
  //   matches: ['Questa Sans'],
  // headingMatches: []
  // },

  {
    name: 'FF Meta Serif',
    fontFamily: 'ff-meta-serif-web-pro, serif',
    headingWeights: [600, 700, 800],
    offset: 1,
    headingOnly: false,
    free: false,
    matches: ['FF Meta'],
    headingMatches: ['FF Meta', 'Adelle Sans', 'Adelle', 'IvyPresto Display']
  },
  // Headings
  {
    name: 'Freight Display',
    fontFamily: 'freight-display-pro, serif',
    headingWeights: [600, 700, 900],
    offset: 2,
    headingOnly: true,
    free: false,
    matches: [],
    headingMatches: []
  },
  {
    name: 'Orpheus Pro',
    fontFamily: 'orpheuspro, serif',
    headingWeights: [500, 700],
    offset: 1,
    headingOnly: true,
    free: false,
    matches: [],
    headingMatches: []
  },
  {
    name: 'IvyPresto Display',
    fontFamily: 'ivypresto-display, sans-serif',
    headingWeights: [400, 600, 700],
    offset: -1,
    headingOnly: true,
    free: false,
    matches: [],
    headingMatches: []
  }
]

export default fonts
