import React, { forwardRef, useCallback, useEffect, useLayoutEffect, useMemo, useReducer, useRef, useState } from 'react'
import classNames from 'classnames'
import axios from 'axios'
import isHotkey from 'is-hotkey'
import isUrl from 'is-url'
import { jsx } from 'slate-hyperscript'
import languages from './languages'
import { Editable, withReact, useSlate, Slate, ReactEditor, useSelected, useFocused, useSlateStatic } from 'slate-react'
import { Editor, Transforms, Element, createEditor, Node, Text, Range, Point } from 'slate'
import { withHistory, HistoryEditor } from 'slate-history'
import QuoteIcon from 'icons/quote.svg'
import HighlightIcon from 'icons/highlight.svg'
import ImageInsetIcon from 'icons/image-inset.svg'
import ImageWideIcon from 'icons/image-wide.svg'
import PlusIcon from 'icons/plus.svg'
import ImageIcon from 'icons/image.svg'
import HTMLIcon from 'icons/code.svg'
import MarkdownIcon from 'icons/markdown.svg'
import Dropdown from './Dropdown'
import Menu from './Menu'

const isInViewport = element => {
  const rect = element.getBoundingClientRect()

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom + 128 <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+e': 'code'
}

const BLOCK_HOTKEYS = {
  'mod+opt+1': 'heading-2',
  'mod+opt+2': 'heading-3',
  'mod+opt+3': 'heading-4',
  'mod+opt+4': 'heading-5'
}

const LIST_TYPES = ['ordered-list', 'list']

const SHORTCUTS = {
  '*': 'list-item',
  '-': 'list-item',
  '+': 'list-item',
  '1.': 'list-item',
  '>': 'block-quote',
  '```': 'code-block',
  '---': 'divider',
  '#': 'heading-2',
  '##': 'heading-3',
  '###': 'heading-4',
  '####': 'heading-5',
  '#####': 'heading-6'
}

const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1

window.images = {}

const ELEMENT_TAGS = {
  A: el => ({ type: 'link', url: el.getAttribute('href') }),
  BLOCKQUOTE: () => ({ type: 'quote' }),
  H1: () => ({ type: 'heading-one' }),
  H2: () => ({ type: 'heading-two' }),
  H3: () => ({ type: 'heading-three' }),
  H4: () => ({ type: 'heading-four' }),
  H5: () => ({ type: 'heading-five' }),
  H6: () => ({ type: 'heading-six' }),
  IMG: el => ({ type: 'image', url: el.getAttribute('src') }),
  LI: () => ({ type: 'list-item' }),
  OL: () => ({ type: 'numbered-list' }),
  P: () => ({ type: 'paragraph' }),
  PRE: () => ({ type: 'code' }),
  UL: () => ({ type: 'bulleted-list' })
}

// COMPAT: `B` is omitted here because Google Docs uses `<b>` in weird ways.
const TEXT_TAGS = {
  CODE: () => ({ code: true }),
  DEL: () => ({ strikethrough: true }),
  EM: () => ({ italic: true }),
  I: () => ({ italic: true }),
  S: () => ({ strikethrough: true }),
  STRONG: () => ({ bold: true }),
  U: () => ({ underline: true })
}

export const deserialize = el => {
  if (el.nodeType === 3) {
    return el.textContent
  } else if (el.nodeType !== 1) {
    return null
  } else if (el.nodeName === 'BR') {
    return '\n'
  }

  const { nodeName } = el
  let parent = el

  if (nodeName === 'PRE' && el.childNodes[0] && el.childNodes[0].nodeName === 'CODE') {
    parent = el.childNodes[0]
  }

  let children = Array.from(parent.childNodes).map(deserialize).flat()

  if (children.length === 0) {
    children = [{ text: '' }]
  }

  if (el.nodeName === 'BODY') {
    return jsx('fragment', {}, children)
  }

  if (ELEMENT_TAGS[nodeName]) {
    const attrs = ELEMENT_TAGS[nodeName](el)
    return jsx('element', attrs, children)
  }

  if (TEXT_TAGS[nodeName]) {
    const attrs = TEXT_TAGS[nodeName](el)
    return children.map(child => jsx('text', attrs, child))
  }

  return children
}

const withEverything = editor => {
  const { insertBreak, addMark, normalizeNode, insertData } = editor

  editor.insertData = data => {
    const text = data.getData('text/plain')
    const [plainBlock] = Editor.nodes(editor, { match: node => ['markdown', 'code-block', 'html'].includes(node.type) })

    if (plainBlock && text) {
      Transforms.insertText(editor, text)
      return
    }

    const html = data.getData('text/html')

    if (html) {
      const parsed = new DOMParser().parseFromString(html, 'text/html')
      const fragment = deserialize(parsed.body)
      Transforms.insertFragment(editor, fragment)
      return
    }

    insertData(data)
  }

  editor.insertBreak = () => {
    const { selection } = editor

    if (selection) {
      // Force line breaks in Mardown and code blocks
      const [block] = Editor.nodes(editor, { match: node => ['markdown', 'code-block', 'html'].includes(node.type) })

      if (block) {
        if (Range.isCollapsed(selection)) {
          const [node, path] = block

          const {
            children: [{ text }]
          } = node

          if (selection.anchor.offset === 0 && text) {
            Transforms.insertNodes(editor, { type: 'paragraph', children: [{ text: '' }] }, { at: path })
            return
          }

          if (selection.anchor.offset === text.length) {
            Transforms.insertNodes(editor, { type: 'paragraph', children: [{ text: '' }] }, { at: [path[0] + 1] })
            Transforms.select(editor, [path[0] + 1, 0])
            return
          }
        }

        Transforms.insertText(editor, '\n')
        return
      }

      // Exit headings, block quotes
      const [exitable] = Editor.nodes(editor, {
        match: node => (node.type && node.type.startsWith('heading')) || ['block-quote', 'caption'].includes(node.type)
      })

      if (exitable) {
        Transforms.splitNodes(editor, { always: true })
        Transforms.setNodes(editor, { type: 'paragraph' })

        const [node] = exitable

        if (node.type === 'caption') {
          Transforms.liftNodes(editor)
        }

        return
      }

      // Exit list
      const match = Editor.above(editor, {
        match: node => ['list-item'].includes(node.type)
      })

      if (match) {
        const [block, path] = match
        const start = Editor.start(editor, path)

        if (block.type === 'list-item' && Point.equals(selection.anchor, start)) {
          Transforms.setNodes(editor, { type: 'paragraph' })

          if (block.type === 'list-item') {
            Transforms.unwrapNodes(editor, {
              match: node => ['list', 'ordered-list'].includes(node.type),
              split: true
            })
          }

          return
        }
      }
    }

    insertBreak()
  }

  editor.addMark = (key, value) => {
    const { selection } = editor

    if (selection) {
      const [markdown] = Editor.nodes(editor, { match: node => ['markdown', 'code-block', 'html'].includes(node.type) })

      if (markdown) {
        return
      }
    }

    addMark(key, value)
  }

  editor.normalizeNode = ([node, path]) => {
    // Only h1 as first child
    if (Element.isElement(node) && node.type && node.type.startsWith('heading') && node.type !== 'heading-1' && path[0] === 0) {
      Transforms.setNodes(editor, { type: 'heading-1' })
      return
    }

    // No h1 after first child
    if (Element.isElement(node) && node.type && node.type === 'heading-1' && path[0] > 0) {
      Transforms.setNodes(editor, { type: 'heading-2' })
      return
    }

    // Flatten headings
    if (
      Element.isElement(node) &&
      node.type.startsWith('heading') &&
      (node.children.length > 1 || Object.keys(node.children[0]).length > 1)
    ) {
      Transforms.unsetNodes(editor, 'code', { at: [path[0], 0] })
      Transforms.unsetNodes(editor, 'italic', { at: [path[0], 0] })
      Transforms.unsetNodes(editor, 'bold', { at: [path[0], 0] })
      Transforms.unsetNodes(editor, 'strikethrough', { at: [path[0], 0] })
      Transforms.unsetNodes(editor, 'highlight', { at: [path[0], 0] })

      if (node.children.length > 1) {
        Transforms.mergeNodes(editor, {
          match: Text.isText,
          at: [path[0], node.children.length - 1]
        })
      }

      return
    }

    // Wrap image in media and add caption if alone
    if (Element.isElement(node) && ['image'].includes(node.type)) {
      if (path.length == 1) {
        const media = { type: 'media', size: 'inset', children: [] }
        const caption = { type: 'caption', children: [{ text: '' }] }
        Transforms.wrapNodes(editor, media, { at: path })
        Transforms.insertNodes(editor, caption)
        return
      }
    }

    // Remove caption if not child of media
    if (Element.isElement(node) && ['media'].includes(node.type)) {
      if (node.children.length == 1) {
        Transforms.removeNodes(editor, { at: path })
        return
      }
    }

    return normalizeNode([node, path])
  }

  return editor
}

const withShortcuts = editor => {
  const { deleteBackward, deleteForward, deleteFragment, insertText, isVoid } = editor

  editor.insertText = text => {
    const [excluded] = Editor.nodes(editor, {
      match: node => ['caption', 'image', 'markdown', 'code-block', 'html'].includes(node.type)
    })

    const { selection } = editor

    if ((text === ' ' || text === '`' || text === '-') && selection && Range.isCollapsed(selection) && !excluded) {
      const { anchor } = selection
      const block = Editor.above(editor, {
        match: n => Editor.isBlock(editor, n)
      })
      const path = block ? block[1] : []
      const start = Editor.start(editor, path)
      const range = { anchor, focus: start }
      const beforeText = Editor.string(editor, range)
      const type = (text === ' ' && SHORTCUTS[beforeText]) || (beforeText && SHORTCUTS[beforeText + text])

      if (type) {
        Transforms.select(editor, range)
        Transforms.delete(editor)
        Transforms.setNodes(editor, { type }, { match: n => Editor.isBlock(editor, n) })

        if (type === 'code-block') {
          const id = Math.floor(Math.random() * 100000000) + 1
          Transforms.setNodes(editor, { language: 'plaintext', id }, { match: n => Editor.isBlock(editor, n) })
        }

        Editor.removeMark(editor, 'code')
        Editor.removeMark(editor, 'bold')
        Editor.removeMark(editor, 'italic')
        Editor.removeMark(editor, 'strikethrough')
        Editor.removeMark(editor, 'highlight')

        if (type === 'list-item') {
          const list = { type: beforeText === '1.' ? 'ordered-list' : 'list', children: [] }
          Transforms.wrapNodes(editor, list, {
            match: node => node.type === 'list-item'
          })
        } else if (type === 'divider') {
          Transforms.select(editor, Editor.after(editor, path))
        }

        return
      }
    }

    // Insert mark anywhere
    if (selection && Range.isCollapsed(selection) && !excluded) {
      const { anchor } = selection
      const previousCharacter = Editor.string(editor, {
        anchor: { ...anchor, offset: anchor.offset - 1 },
        focus: anchor
      })

      if (previousCharacter === '`' && text !== '`' && text !== ' ') {
        Transforms.delete(editor, { distance: 1, unit: 'character', reverse: true })
        Editor.addMark(editor, 'code', true)
      }
    }

    insertText(text)
  }

  editor.deleteFragment = () => {
    const [match] = Editor.nodes(editor, {
      match: node => node.type === 'image'
    })

    if (match) {
      Transforms.removeNodes(editor, { at: [match[1][0]] })
    }

    deleteFragment()
  }

  editor.deleteFragment = () => {
    const [match] = Editor.nodes(editor, {
      match: node => node.type === 'caption'
    })

    if (match) {
      const { selection } = editor
      const [start, end] = Range.edges(selection)

      if (start.offset === 0 && end.path[0] > start.path[0]) {
        Transforms.removeNodes(editor, { at: [match[1][0]] })
      }
    }

    deleteFragment()
  }

  editor.deleteBackward = (...args) => {
    const { selection } = editor

    if (selection && Range.isCollapsed(selection)) {
      const match = Editor.above(editor, {
        match: n => Editor.isBlock(editor, n)
      })

      if (match) {
        const [block, path] = match
        const start = Editor.start(editor, path)
        const previous = Editor.previous(editor, { at: path })

        if (previous) {
          const [previousElement, previousPath] = previous

          if (previousElement.type === 'media' && Point.equals(selection.anchor, start)) {
            Transforms.select(editor, [...previousPath, 1])
            Transforms.collapse(editor, { edge: 'end' })
            return
          }
        }

        if ((block.type === 'caption' && Point.equals(selection.anchor, start)) || block.type === 'image') {
          const [, mediaPath] = Editor.above(editor, { at: path })

          Transforms.removeNodes(editor, { at: mediaPath })
          return
        }

        if (block.type !== 'paragraph' && Point.equals(selection.anchor, start)) {
          Transforms.setNodes(editor, { type: 'paragraph' })

          if (block.type === 'list-item') {
            Transforms.unwrapNodes(editor, {
              match: node => node.type === 'list',
              split: true
            })
          }

          return
        }
      }
    }

    deleteBackward(...args)
  }

  editor.deleteForward = (...args) => {
    const { selection } = editor

    if (selection && Range.isCollapsed(selection)) {
      const match = Editor.above(editor, {
        match: n => Editor.isBlock(editor, n)
      })

      if (match) {
        const [block, path] = match
        const end = Editor.end(editor, path)
        const next = Editor.next(editor, { at: path })

        if (next) {
          const [nextElement, nextPath] = next

          if (nextElement.type === 'media' && Point.equals(selection.anchor, end)) {
            Transforms.removeNodes(editor, { at: nextPath })
            return
          }
        }

        if (block.type === 'image') {
          const [, mediaPath] = Editor.above(editor, { at: path })
          Transforms.removeNodes(editor, { at: mediaPath })
          return
        }

        if (block.type === 'caption' && Point.equals(selection.anchor, end)) {
          return
        }
      }
    }

    deleteForward(...args)
  }

  editor.isVoid = element => {
    return element.type === 'divider' ? true : isVoid(element)
  }

  return editor
}

const withImages = editor => {
  const { insertData, isVoid, insertBreak, deleteBackward } = editor

  editor.isVoid = element => {
    return element.type === 'image' ? true : isVoid(element)
  }

  editor.insertData = data => {
    const text = data.getData('text/plain')
    const { files } = data

    if (files && files.length > 0) {
      insertImageFiles(editor, files)
      return
      // } else if (isImageUrl(text)) {
      //   insertImage(editor, text)
      //   return
    }

    insertData(data)
  }

  editor.insertBreak = () => {
    const { selection } = editor

    if (selection) {
      const [image] = Editor.nodes(editor, {
        match: node => node.type === 'image'
      })

      if (image) {
        Transforms.select(editor, Editor.after(editor, image[1]))
        return
      }

      const [divider] = Editor.nodes(editor, {
        match: node => node.type === 'divider'
      })

      if (divider) {
        Transforms.insertNodes(editor, { type: 'paragraph', children: [{ text: '' }] })
        return
      }

      const [caption] = Editor.nodes(editor, {
        match: node => node.type === 'caption'
      })

      if (caption) {
        const [, path] = caption
        const end = Editor.end(editor, path)

        if (!Point.equals(selection.anchor, end)) {
          return
        }
      }
    }

    insertBreak()
  }

  return editor
}

const withLinks = editor => {
  const { insertData, insertText, isInline } = editor

  editor.isInline = element => {
    return element.type === 'link' ? true : isInline(element)
  }

  editor.insertText = text => {
    if (text && isUrl(text)) {
      wrapLink(editor, text)
    } else {
      insertText(text)
    }
  }

  editor.insertData = data => {
    const text = data.getData('text/plain')

    const [excluded] = Editor.nodes(editor, {
      match: node => ['caption', 'image', 'markdown', 'code-block', 'html'].includes(node.type)
    })

    if (text && isUrl(text) && !excluded) {
      wrapLink(editor, text)
    } else {
      insertData(data)
    }
  }

  return editor
}

const insertLink = (editor, url) => {
  if (editor.selection) {
    wrapLink(editor, url)
  }
}

const isLinkActive = editor => {
  const [link] = Editor.nodes(editor, { match: n => n.type === 'link' })
  return !!link
}

const unwrapLink = editor => {
  Transforms.unwrapNodes(editor, { match: n => n.type === 'link' })
}

const wrapLink = (editor, url) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor)
  }

  const { selection } = editor
  const isCollapsed = selection && Range.isCollapsed(selection)
  const link = {
    type: 'link',
    url,
    children: isCollapsed ? [{ text: url }] : []
  }

  if (isCollapsed) {
    Transforms.insertNodes(editor, link)
  } else {
    Transforms.wrapNodes(editor, link, { split: true })
    Transforms.collapse(editor, { edge: 'end' })
  }
}

const withLayout = editor => {
  const { normalizeNode } = editor

  editor.normalizeNode = ([node, path]) => {
    if (path.length === 0) {
      if (editor.children.length < 1) {
        const title = {
          type: 'heading-1',
          children: [{ text: 'Title' }]
        }
        Transforms.insertNodes(editor, title, { at: path.concat(0) })
      }

      if (editor.children.length < 2) {
        const paragraph = {
          type: 'paragraph',
          children: [{ text: '' }]
        }
        Transforms.insertNodes(editor, paragraph, { at: path.concat(1) })
      }

      for (const [child, childPath] of Node.children(editor, path)) {
        if (childPath[0] === 0 && Element.isElement(child) && child.type !== 'heading-1') {
          Transforms.setNodes(editor, { type: 'heading-1' }, { at: childPath })
        }
      }
    }

    return normalizeNode([node, path])
  }

  return editor
}

export default function RichEditor({ text, onTextChange }) {
  const renderElement = useCallback(props => <Block {...props} />, [])
  const renderLeaf = useCallback(props => <Leaf {...props} />, [])

  const editor = useMemo(() => withHistory(withShortcuts(withLayout(withLinks(withImages(withEverything(withReact(createEditor()))))))), [])

  const linkInputRef = useRef()
  const [link, setLink] = useState('')
  const [lastSelection, setLastSelection] = useState()

  const isBlank = text.length === 1 && text[0].children.length === 1 && text[0].type === 'paragraph' && text[0].children[0].text === ''

  const handleUploadImage = event => {
    insertImageFiles(editor, event.target.files)
  }

  useLayoutEffect(() => {
    if (editor.selection == null) return
    try {
      const { selection } = editor
      const domPoint = ReactEditor.toDOMPoint(editor, selection.focus)
      const node = domPoint[0]
      if (node == null) return

      const element = node.parentElement
      if (element == null) return

      const { anchor } = selection
      const slateNode = Node.get(editor, [anchor.path[0]])
      const isAtPlainBlock = ['markdown', 'html', 'code-block'].includes(slateNode.type)

      if (!isInViewport(element) && !isAtPlainBlock) {
        element.scrollIntoView({ block: 'start' })
      }
    } catch {}
  }, [text])

  return (
    <div className='editor_content'>
      <input id='imageInput' hidden type='file' onChange={handleUploadImage} multiple accept='.png,.jpeg,.jpg,.gif' />

      <Slate editor={editor} value={text} onChange={onTextChange}>
        <Toolbar
          ref={linkInputRef}
          link={link}
          lastSelection={lastSelection}
          onLinkChange={setLink}
          onLastSelectionChange={setLastSelection}
        />

        <Editable
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          spellCheck
          autoFocus
          className='editor_text'
          decorate={([node, path]) => {
            if (!Editor.isEditor(node) && editor.children.length === 2 && path[0] === 1 && Editor.string(editor, [1]) === '') {
              return [
                {
                  anchor: {
                    path: [1, 0],
                    offset: 0
                  },
                  focus: {
                    path: [1, 0],
                    offset: 0
                  },
                  placeholder: true
                }
              ]
            }
            return []
          }}
          onKeyDown={event => {
            for (const hotkey in HOTKEYS) {
              if (isHotkey(hotkey, event)) {
                event.preventDefault()
                const mark = HOTKEYS[hotkey]
                toggleMark(editor, mark)
              }
            }

            for (const hotkey in BLOCK_HOTKEYS) {
              if (isHotkey(hotkey, event)) {
                event.preventDefault()
                const block = BLOCK_HOTKEYS[hotkey]
                toggleBlock(editor, block)
              }
            }

            if (isHotkey('shift+enter', event)) {
              const [match] = Editor.nodes(editor, {
                match: node => node.type && node.type.startsWith('heading')
              })

              if (!match) {
                Transforms.insertText(editor, '\n')
                event.preventDefault()
              }
            }

            const { selection } = editor

            if (selection) {
              const { anchor, focus } = selection
              const isMultiline = anchor.path[0] !== focus.path[0]

              if (isHotkey('mod+k', event) && !isMultiline) {
                if (isLinkActive(editor)) {
                  unwrapLink(editor)
                } else {
                  const linkInput = linkInputRef.current
                  setLink('')
                  setLastSelection(selection)
                  linkInput.classList.add('-keep')
                  linkInput.classList.add('-active')

                  // try {
                  //   navigator.clipboard.readText().then(text => {
                  //     if (isUrl(text)) {
                  //       setLink(text)
                  //     }
                  //   })
                  // } catch {}

                  linkInput.focus()
                }

                event.preventDefault()
              }
            }
          }}
        />
      </Slate>
    </div>
  )
}

const toggleBlock = (editor, format) => {
  const [caption] = Editor.nodes(editor, { match: node => ['caption', 'image'].includes(node.type) })

  if (caption) {
    return
  }

  const isActive = isBlockActive(editor, format)
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, {
    match: node => LIST_TYPES.includes(node.type),
    split: true
  })

  let type

  if (isActive) {
    type = 'paragraph'
  } else {
    if (isList) {
      type = 'list-item'
    } else {
      type = format
    }
  }

  if (!isActive && format == 'heading-1') {
    const { selection } = editor
    const [start, end] = Range.edges(selection)

    if (start.path[0] !== end.path[0] && end.offset > 0) {
      Transforms.setNodes(editor, { type }, { at: [0] })
      Transforms.setNodes(editor, { type: 'heading-2' }, { at: { anchor: { path: [1, 0], offset: 0 }, focus: end } })
      return
    }
  }

  Transforms.setNodes(editor, { type })

  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

const insertImageFiles = (editor, files) => {
  for (const file of files) {
    const reader = new FileReader()
    const [mime] = file.type.split('/')

    if (mime === 'image') {
      reader.addEventListener('load', () => {
        const id = Math.floor(Math.random() * 100000000) + 1
        window.images[id] = reader.result

        HistoryEditor.withoutSaving(editor, () => {
          insertImage(editor, null, id)
        })

        let formData = new FormData()
        formData.set('image[file]', file)

        axios
          .post('/images', formData, {
            headers: {
              'content-type': 'multipart/form-data'
            }
          })
          .then(response => {
            const {
              data: {
                image: { url }
              }
            } = response

            const imageFile = new Image()

            imageFile.onload = () => {
              const [[image, path]] = Editor.nodes(editor, {
                at: { anchor: { path: [0], offset: 0 }, focus: { path: [editor.children.length - 1], offset: 0 } },
                match: node => node.id === id && node.type === 'image'
              })

              const [media, mediaPath] = Editor.above(editor, {
                at: path,
                match: node => node.type === 'media'
              })
              const lastSelection = editor.selection

              HistoryEditor.withoutSaving(editor, () => {
                Transforms.removeNodes(editor, {
                  at: mediaPath
                })
              })

              Transforms.insertNodes(
                editor,
                {
                  ...media,
                  children: [{ ...media.children[0], url, width: imageFile.width }, media.children[1]]
                },
                { at: mediaPath }
              )

              Transforms.select(editor, lastSelection)
              window.images[id] = ''
            }

            imageFile.src = url
          })
          .catch(function (error) {
            console.log(error)
          })

        return
      })

      reader.readAsDataURL(file)
    }
  }
}

const insertImage = (editor, url, id) => {
  const image = { type: 'image', url, id, children: [{ text: '' }] }
  const caption = { type: 'caption', children: [{ text: '' }] }
  const media = { type: 'media', size: 'inset', children: [image, caption] }
  const { selection } = editor
  Transforms.insertNodes(editor, media, { at: [selection.anchor.path[0]] })
  Transforms.select(editor, [selection.anchor.path[0], 1])
}

const isBlockActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: node => node.type === format
  })

  return !!match
}

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor)
  return marks ? marks[format] === true : false
}

const isImageUrl = url => {
  if (!url) return false
  if (!isUrl(url)) return false
  const extension = new URL(url).pathname.split('.').pop()
  return ['jpg', 'jpeg', 'gif', 'png'].includes(extension)
}

const Block = props => {
  const { attributes, children, element } = props
  const focused = useFocused()
  const selected = useSelected()
  const editor = useSlateStatic()

  const showMenu =
    selected &&
    focused &&
    editor.selection &&
    Range.isCollapsed(editor.selection) &&
    element.children.length === 1 &&
    !element.children[0].text

  switch (element.type) {
    case 'heading-1':
      return (
        <h1 {...attributes}>
          {element.children.length === 1 && !element.children[0].text && (
            <span className='editor_placeholder' contentEditable={false}>
              Title
            </span>
          )}
          {children}
        </h1>
      )
    case 'heading-2':
      return <h2 {...attributes}>{children}</h2>
    case 'heading-3':
      return <h3 {...attributes}>{children}</h3>
    case 'heading-4':
      return <h4 {...attributes}>{children}</h4>
    case 'heading-5':
      return <h5 {...attributes}>{children}</h5>
    case 'heading-6':
      return <h6 {...attributes}>{children}</h6>
    case 'list':
      return <ul {...attributes}>{children}</ul>
    case 'ordered-list':
      return <ol {...attributes}>{children}</ol>
    case 'list-item':
      return <li {...attributes}>{children}</li>
    case 'link':
      return (
        <a {...attributes} href={element.url}>
          {children}
        </a>
      )
    case 'divider':
      return (
        <div {...attributes} className={classNames('editor_divider', { '-focused': selected && focused })}>
          {children}
        </div>
      )
    case 'markdown':
      return (
        <div {...attributes} className='editor_markdown'>
          {children}
        </div>
      )
    case 'html':
      return (
        <div {...attributes} className='editor_html'>
          {children}
        </div>
      )
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>
    case 'code-block':
      return <CodeBlock {...props} />
    case 'image':
      return <ImageElement {...props} />
    case 'caption':
      return (
        <p className={classNames('editor_caption', { '-empty': !element.children[0].text })} {...attributes}>
          {children}
        </p>
      )
    case 'media':
      return (
        <div className={classNames('editor_media', { '-wide': element.size === 'wide' })} {...attributes}>
          {children}
        </div>
      )
    default:
      return (
        <div className='editor_paragraph'>
          <p {...attributes}>{children}</p>
          {showMenu && <SideMenu />}
        </div>
      )
  }
}

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.placeholder) {
    children = (
      <>
        <span className='editor_placeholder' contentEditable={false}>
          Start writing...
        </span>
        {children}
      </>
    )
  }

  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.code) {
    children = <code>{children}</code>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.strikethrough) {
    children = <del>{children}</del>
  }

  if (leaf.highlight) {
    children = <mark>{children}</mark>
  }

  return <span {...attributes}>{children}</span>
}

const ImageElement = ({ attributes, children, element }) => {
  const selected = useSelected()
  const focused = useFocused()

  return (
    <div {...attributes}>
      {children}
      <div contentEditable={false}>
        <img src={element.url || images[element.id]} className={classNames('editor_image', { '-focused': selected && focused })} />
      </div>
    </div>
  )
}
const CodeBlock = ({ attributes, children, element }) => {
  const editor = useSlate()
  const [lastSelection, setLastSelection] = useState(editor.selection)

  return (
    <pre>
      <code {...attributes}>{children}</code>

      <select
        className='select -smaller -light'
        contentEditable={false}
        value={element.language}
        onMouseDown={event => {
          if (editor.selection) {
            setLastSelection(editor.selection)
          }
        }}
        onChange={event => {
          Transforms.setNodes(
            editor,
            { language: event.target.value },
            { at: editor, match: node => node.type === 'code-block' && node.id === element.id }
          )

          if (lastSelection) {
            ReactEditor.focus(editor)
            Transforms.select(editor, lastSelection)
            setLastSelection(null)
          }
        }}
      >
        {languages.map(language => (
          <option key={language.key} value={language.key}>
            {language.name}
          </option>
        ))}
      </select>
    </pre>
  )
}

const Toolbar = forwardRef(({ link, lastSelection, onLinkChange, onLastSelectionChange }, linkInputRef) => {
  const ref = useRef()
  const editor = useSlate()
  const disappearTimeoutRef = useRef()
  const [, forceUpdate] = useReducer(x => x + 1, 0)

  const toolbar = ref.current
  const linkInput = linkInputRef.current
  const { selection } = editor
  let isAtStart = false
  let isAtHeading = false
  let isAtMedia = false
  let isAtPlainBlock = false
  let isAtImage = false
  let isMultiline = false
  let headingText = 'Heading'
  let headingKey = 'heading-2'

  let node = null
  let parentNode = null

  if (selection) {
    const { anchor, focus } = selection
    parentNode = Node.get(editor, [anchor.path[0]])
    node = Node.parent(editor, anchor.path)

    isAtStart = anchor.path[0] === 0 || focus.path[0] === 0
    isAtHeading = parentNode.type.startsWith('heading')
    isMultiline = anchor.path[0] !== focus.path[0]
    isAtMedia = parentNode.type === 'media'
    isAtImage = node.type === 'image'
    isAtPlainBlock = ['markdown', 'html', 'code-block'].includes(parentNode.type)

    if (parentNode.type === 'heading-2') {
      headingKey = 'heading-3'
    } else if (parentNode.type === 'heading-3') {
      headingText = 'Heading 2'
      headingKey = 'heading-4'
    } else if (parentNode.type === 'heading-4') {
      headingText = 'Heading 3'
      headingKey = 'heading-5'
    } else if (parentNode.type === 'heading-5') {
      headingText = 'Heading 4'
      headingKey = 'heading-5'
    }
  }

  useEffect(() => {
    const element = ref.current

    const setDown = event => {
      if (!element.contains(event.target)) {
        element.classList.add('-down')
      }
    }

    const unsetDown = () => {
      element.classList.remove('-down')
    }

    document.addEventListener('mousedown', setDown)
    document.addEventListener('mouseup', unsetDown)

    return () => {
      document.removeEventListener('mousedown', setDown)
      document.removeEventListener('mouseup', unsetDown)
    }
  }, [])

  useEffect(() => {
    const handleResize = () => {
      forceUpdate()
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (!toolbar || linkInput.classList.contains('-keep')) {
      return
    }

    if (
      !selection ||
      !ReactEditor.isFocused(editor) ||
      ((Range.isCollapsed(selection) || Editor.string(editor, selection) === '' || isAtPlainBlock) && !isAtImage) ||
      isAtStart
    ) {
      if (toolbar.classList.contains('-active')) {
        toolbar.classList.remove('-active')
        toolbar.style.transform += ' scale(.95)'

        disappearTimeoutRef.current = setTimeout(() => {
          toolbar.style.transition = 'none'
          toolbar.offsetHeight
          toolbar.style.transform = 'translate3d(-999px, -999px, 0)'
          linkInput.classList.remove('-active')
        }, 200)
      }

      return
    }

    clearTimeout(disappearTimeoutRef.current)
    const domSelection = window.getSelection()
    const domRange = domSelection.getRangeAt(0)
    const rect = domRange.getBoundingClientRect()
    const translate = `translate3d(
      ${Math.max(
        0,
        Math.round((isAtImage ? window.innerWidth / 2 : rect.left + window.pageXOffset + rect.width / 2) - toolbar.offsetWidth / 2)
      )}px,
      ${Math.round(rect.top + window.pageYOffset - toolbar.offsetHeight) + (isAtImage ? -6 : 2)}px,
      0
    )`

    if (!toolbar.classList.contains('-active')) {
      linkInput.classList.remove('-active')
      toolbar.style.transition = 'none'
      toolbar.style.transform = `${translate} scale(.95)`
      toolbar.offsetHeight
      toolbar.style.transition = null
      toolbar.classList.add('-active')
    }

    toolbar.style.transform = translate
  })

  return (
    <div
      className='toolbar'
      ref={ref}
      onClick={event => {
        event.preventDefault()
      }}
      onMouseDown={event => {
        event.preventDefault()
      }}
    >
      <input
        type='text'
        className='toolbar_input'
        value={link}
        placeholder='Enter your link'
        onChange={event => {
          onLinkChange(event.target.value)
        }}
        onBlur={event => {
          if (linkInput.classList.contains('-keep')) {
            linkInput.classList.remove('-keep')

            if (link) {
              ReactEditor.focus(editor)
              Transforms.select(editor, lastSelection)
              insertLink(editor, link)
            }
          }
        }}
        ref={linkInputRef}
        onKeyDown={event => {
          if (isHotkey('enter', event) && link) {
            ReactEditor.focus(editor)
            event.preventDefault()
          }

          if (isHotkey('esc', event)) {
            linkInput.classList.remove('-keep')
            linkInput.classList.remove('-active')
            ReactEditor.focus(editor)
            Transforms.select(editor, lastSelection)
            event.preventDefault()
          }

          if (isHotkey('tab', event)) {
            event.preventDefault()
          }
        }}
      />

      {!isAtMedia && (
        <>
          <button
            className={classNames('toolbar_button -heading', {
              ' -active':
                isBlockActive(editor, 'heading-2') ||
                isBlockActive(editor, 'heading-3') ||
                isBlockActive(editor, 'heading-4') ||
                isBlockActive(editor, 'heading-5')
            })}
            onMouseDown={event => {
              event.preventDefault()
              toggleBlock(editor, headingKey)
            }}
          >
            {headingText}
          </button>

          <button
            className={classNames('toolbar_button', {
              ' -active': isBlockActive(editor, 'block-quote')
            })}
            onMouseDown={event => {
              event.preventDefault()
              toggleBlock(editor, 'block-quote')
            }}
          >
            <QuoteIcon />
          </button>

          <span className='toolbar_separator'></span>
        </>
      )}

      {isAtImage ? (
        <>
          <button
            className={classNames('toolbar_button', {
              ' -active': parentNode.size === 'inset'
            })}
            onMouseDown={event => {
              event.preventDefault()
              Transforms.setNodes(editor, { size: 'inset' }, { at: [selection.anchor.path[0]] })
            }}
          >
            <ImageInsetIcon />
          </button>

          {
            <button
              className={classNames('toolbar_button', {
                ' -active': parentNode.size === 'wide',
                '-disabled': node.width <= 728
              })}
              onMouseDown={event => {
                event.preventDefault()
                Transforms.setNodes(editor, { size: 'wide' }, { at: [selection.anchor.path[0]] })
              }}
            >
              <ImageWideIcon />
            </button>
          }
        </>
      ) : (
        <>
          <button
            className={classNames('toolbar_button -bold', {
              ' -active': isMarkActive(editor, 'bold'),
              '-disabled': isAtHeading
            })}
            onMouseDown={event => {
              event.preventDefault()
              toggleMark(editor, 'bold')
            }}
          >
            B
          </button>
          <button
            className={classNames('toolbar_button -italic', {
              ' -active': isMarkActive(editor, 'italic'),
              '-disabled': isAtHeading
            })}
            onMouseDown={event => {
              event.preventDefault()
              toggleMark(editor, 'italic')
            }}
          >
            I
          </button>
          <button
            className={classNames('toolbar_button -strikethrough', {
              '-active': isMarkActive(editor, 'strikethrough'),
              '-disabled': isAtHeading
            })}
            onMouseDown={event => {
              event.preventDefault()
              toggleMark(editor, 'strikethrough')
            }}
          >
            S
          </button>

          <button
            className={classNames('toolbar_button -strikethrough', {
              ' -active': isMarkActive(editor, 'highlight'),
              '-disabled': isAtHeading
            })}
            onMouseDown={event => {
              event.preventDefault()
              toggleMark(editor, 'highlight')
            }}
          >
            <HighlightIcon />
          </button>

          <button
            className={classNames('toolbar_button -link', {
              '-active': isLinkActive(editor),
              '-disabled': isAtHeading || isMultiline
            })}
            onMouseDown={event => {
              if (isLinkActive(editor)) {
                unwrapLink(editor)
              } else {
                onLinkChange('')
                onLastSelectionChange(editor.selection)
                linkInput.classList.add('-keep')
                linkInput.classList.add('-active')
                linkInput.focus()

                // try {
                //   navigator.clipboard.readText().then(text => {
                //     if (isUrl(text)) {
                //       onLinkChange(text)
                //     }
                //   })
                // } catch {}
              }

              event.preventDefault()
            }}
          >
            Link
          </button>
        </>
      )}
    </div>
  )
})

const SideMenu = () => {
  const ref = useRef()
  const editor = useSlate()
  const [active, setActive] = useState(false)

  return (
    <div className={classNames('editor_menu', { '-active': active })} ref={ref} contentEditable={false}>
      <Dropdown
        isOpen={active}
        origin='left'
        onToggle={event => {
          setActive(!active)
        }}
        renderToggle={(isOpen, onToggle) => (
          <button
            className={classNames('button -secondary -small -circle', { '-active': active })}
            onClick={onToggle}
            onMouseDown={event => {
              event.preventDefault()
            }}
          >
            <PlusIcon />
          </button>
        )}
      >
        <Menu>
          <button
            className='menu_item'
            onClick={() => {
              document.getElementById('imageInput').click()
            }}
            onMouseDown={event => {
              event.preventDefault()
            }}
          >
            <ImageIcon />
            Image
          </button>

          <button
            className='menu_item'
            onClick={() => {
              Transforms.setNodes(editor, { type: 'markdown' }, { match: n => Editor.isBlock(editor, n) })
            }}
            onMouseDown={event => {
              event.preventDefault()
            }}
          >
            <MarkdownIcon />
            Markdown
          </button>

          <button
            className='menu_item'
            onClick={() => {
              Transforms.setNodes(editor, { type: 'html' }, { match: n => Editor.isBlock(editor, n) })
            }}
            onMouseDown={event => {
              event.preventDefault()
            }}
          >
            <HTMLIcon />
            HTML
          </button>
        </Menu>
      </Dropdown>
    </div>
  )
}
