import React from 'react'
import classNames from 'classnames'
import Navigation from './Navigation'
import { sumBy } from 'lodash'

const Header = ({ site }) => {
  const internalLinks = site.internal_links_attributes.filter(item => item.text || item.icon)

  return (
    <header className={classNames('container site-header', { '-max-8': sumBy(internalLinks, link => link.text.length + 3) <= 48 && site.theme.posts.format !== 'grid' && !site.heading })}>
      <a className='site-logo'>{site.logo ? <img src={site.logo} alt={site.name} /> : site.name}</a>
      <Navigation site={site} />
    </header>
  )
}

export default Header
