import React from 'react'
import classNames from 'classnames'

const Input = React.forwardRef(({ error, small, smaller, ...otherProps }, ref) => (
  <input
    type='text'
    ref={ref}
    className={classNames('input', { '-error': error, '-small': small, '-smaller': smaller })}
    {...otherProps}
  />
))

export default Input
