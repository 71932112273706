import React from 'react'
import classNames from 'classnames'

export default function Field({ children, className, label, htmlFor, error, description, ...otherProps }) {
  return (
    <div className={classNames('field', className)} {...otherProps}>
      {label && (
        <label className='field_label' htmlFor={htmlFor}>
          {label}
        </label>
      )}

      {children}

      {error && <span className='field_error'>{error}</span>}
      {description && <span className='field_description'>{description}</span>}
    </div>
  )
}
