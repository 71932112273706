import React from 'react'
import { bool, oneOf, shape, string, object, array } from 'prop-types'
import classNames from 'classnames'
import { drop, isEqual } from 'lodash'
import axios from 'axios'
import { parseISO, isFuture, isPast } from 'date-fns'
import { DatePicker } from 'react-nice-dates'
import { enGB } from 'date-fns/locale'
import Field from 'components/Field'
import Dropdown from 'components/Dropdown'
import Menu from 'components/Menu'
import MultiSelect from 'components/MultiSelect'
import MarkdownEditor from 'components/MarkdownEditor'
import RichEditor from 'components/RichEditor'
import Textarea from 'react-expanding-textarea'
import BackIcon from 'icons/chevron-left.svg'
import ViewIcon from 'icons/arrow-up-right.svg'
import SettingsIcon from 'icons/settings.svg'
import MoreIcon from 'icons/more.svg'
import UnpublishIcon from 'icons/rotate-left.svg'

const isScrolled = () => window.scrollY > 140 - 88

const parseText = (document, newEditor) => {
  if (!document.text) {
    if (newEditor) {
      return [
        {
          type: 'heading-1',
          children: [{ text: '' }]
        },
        {
          type: 'paragraph',
          children: [{ text: '' }]
        }
      ]
    } else {
      return [
        {
          type: 'paragraph',
          children: [{ text: '' }]
        }
      ]
    }
  } else {
    if (newEditor) {
      return [
        {
          type: 'heading-1',
          children: [{ text: document.title || '' }]
        },
        ...document.text.children
      ]
    } else {
      return document.text.children
    }
  }
}

export default class Editor extends React.Component {
  static propTypes = {
    initialDocument: shape({
      id: string,
      url: string,
      previewUrl: string,
      published: bool,
      unpublishedChanges: bool,
      scheduled: bool,
      home: bool,
      title: string,
      text: object,
      tags: array,
      slug: string,
      date: string,
      excerpt: string,
      code_head: string,
      type: oneOf(['post', 'page'])
    }),
    siteUrl: string,
    token: string,
    newEditor: bool,
    pro: bool
  }

  state = {
    ...this.props.initialDocument,
    text: parseText(this.props.initialDocument, this.props.newEditor),
    saved: true,
    saving: false,
    publishing: false,
    unpublishing: false,
    dropdownOpen: false,
    settingsOpen: false,
    scheduleOpen: false,
    settingsSaved: true,
    settingsSaving: false,
    settingsAdvanced: false,
    submitted: false,
    scrolled: false,
    focused: false,
    date: this.props.initialDocument.date ? parseISO(this.props.initialDocument.date) : null,
    dateChanged: false
  }

  componentDidMount() {
    axios.defaults.headers.common['X-CSRF-Token'] = this.props.token
    this.interval = setInterval(this.handleSave, 2000)
    window.addEventListener('scroll', this.handleScroll, false)
    window.addEventListener('mousemove', this.handleMove, false)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, false)
    window.removeEventListener('mousemove', this.handleMove, false)
  }

  handleScroll = () => {
    const scrolled = isScrolled()

    if (this.state.scrolled !== scrolled || this.state.focused) {
      this.setState({ scrolled })
    }
  }

  handleUnfocus = () => {
    if (this.state.focused) {
      this.setState({ focused: false })
    }
  }

  handleFieldChange = name => event => {
    const { value } = event.target
    const newState = { [name]: value }

    if (name === 'tags' || name === 'slug' || name === 'excerpt' || name === 'code_head' || name === 'user_id') {
      newState.settingsSaved = false
    }

    this.setState(newState)
  }

  handleTitleChange = (value, callback) => {
    this.setState({ title: value, saved: false, focused: true }, callback)
  }

  handleTextChange = (value, callback) => {
    const changed = !isEqual(this.state.text, value)
    const newState = { text: value }

    if (changed) {
      newState.saved = false
      newState.focused = true
    }

    this.setState(newState, callback)
  }

  handleDateChange = date => {
    this.setState({ date, settingsSaved: false, dateChanged: true })
  }

  handleSave = () => {
    if (!this.state.saved || this.state.saving) {
      // To do: saving should always reflect last request
      this.setState({ saved: true, saving: true })

      let title
      let text

      if (this.props.newEditor) {
        if (this.state.text[0].type === 'heading-1') {
          title = this.state.text[0].children[0].text
          text = drop(this.state.text)
        } else {
          title = ''
          text = this.state.text
        }
      } else {
        title = this.state.title
        text = this.state.text
      }

      document.title = `${title || 'Untitled'} | Bloggi`

      axios
        .patch(`/${this.props.initialDocument.type}s/${this.props.initialDocument.id}`, {
          [this.props.initialDocument.type]: {
            title,
            text: { children: text }
          }
        })
        .then(response => {
          const {
            data: {
              [this.props.initialDocument.type]: { unpublishedChanges }
            }
          } = response

          this.setState({
            unpublishedChanges,
            saving: false
          })
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }

  handleSaveSettings = event => {
    this.setState({
      submitted: true,
      settingsSaving: true
    })

    const {
      initialDocument: { type, id }
    } = this.props

    const payload = {
      slug: this.state.slug,
      code_head: this.state.code_head,
      excerpt: this.state.excerpt,
      image_url: this.state.image_url,
      user_id: this.state.user_id
    }

    if (type === 'post') {
      payload.tag_names = this.state.tags.join(',')

      if (this.state.dateChanged) {
        payload.published_at = this.state.date && this.state.date.toUTCString()
      }
    }

    axios
      .patch(`/${type}s/${id}`, {
        [type]: payload
      })
      .then(response => {
        const {
          data: {
            [type]: { slug, url, date, scheduled, published, tags }
          }
        } = response

        this.setState({
          settingsSaving: false,
          settingsSaved: true,
          settingsOpen: false,
          submitted: false,
          dateChanged: false,
          published,
          scheduled,
          slug,
          url,
          tags,
          date: date ? parseISO(date) : null
        })
      })
      .catch(function (error) {
        console.log(error)
      })

    event.preventDefault()
  }

  handlePublish = () => {
    this.setState({ publishing: true })

    axios
      .patch(`/${this.props.initialDocument.type}s/${this.props.initialDocument.id}/publish`)
      .then(response => {
        const {
          data: {
            [this.props.initialDocument.type]: { url, slug, date }
          }
        } = response

        this.setState({
          published: true,
          publishing: false,
          unpublishedChanges: false,
          url,
          slug,
          date: parseISO(date)
        })
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  handleUnpublish = () => {
    this.setState({ unpublishing: true })

    axios
      .patch(`/${this.props.initialDocument.type}s/${this.props.initialDocument.id}/unpublish`)
      .then(response => {
        const {
          data: {
            [this.props.initialDocument.type]: { slug }
          }
        } = response

        this.setState({
          unpublishing: false,
          published: false,
          slug,
          date: null
        })
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  handleSettingsToggle = open => {
    this.setState({ settingsOpen: open })
  }

  handleSettingsAdvancedToggle = () => {
    this.setState({ settingsAdvanced: !this.state.settingsAdvanced })
  }

  handleUploadImage = event => {
    const file = event.target.files[0]

    if (file) {
      const reader = new FileReader()

      reader.addEventListener('load', () => {
        let formData = new FormData()
        formData.set('image[file]', file)

        axios
          .post('/images', formData, {
            headers: {
              'content-type': 'multipart/form-data'
            }
          })
          .then(response => {
            const {
              data: {
                image: { url }
              }
            } = response

            this.setState({ image_url: url, settingsSaved: false })
          })
          .catch(function (error) {
            console.log(error)
          })

        return
      })

      reader.readAsDataURL(file)
    }
  }

  render() {
    const potentialSlug =
      this.state.title
        .trim()
        .toLowerCase()
        .replace(/[^a-zA-Z0-9 -]/, '')
        .replace(/\s/g, '-') || 'title'

    const isBlank =
      this.state.text.length === 1 &&
      this.state.text[0].children.length === 1 &&
      this.state.text[0].type === 'paragraph' &&
      this.state.text[0].children[0].text === ''

    let firstImage = this.state.text.find(block => block.type === 'media')?.children[0].url

    if (!firstImage) {
      this.state.text
        .filter(block => block.type === 'markdown')
        .some(block => {
          const match = block.children[0].text.match(/!\[.*?\]\((.*?)\)/)

          if (match) {
            firstImage = match[1]
          }

          return match
        })
    }

    return (
      <div className={classNames('editor', { '-focus': this.state.focused })}>
        <div className={classNames('editor_header', { '-scrolled': this.state.scrolled })} onMouseEnter={this.handleUnfocus}>
          <a
            href={`/${this.props.initialDocument.type}s${this.state.published ? '/published' : ''}`}
            className='button -small -secondary -back editor_desktop-only mr-auto'
          >
            <BackIcon style={{ marginLeft: -6, marginRight: 2 }} />
            Back
          </a>

          <a
            href={`/${this.props.initialDocument.type}s${this.state.published ? '/published' : ''}`}
            className='button -small -secondary -square editor_mobile-only mr-auto'
          >
            <BackIcon />
          </a>

          <button
            className='button -small ml-16'
            onClick={this.handlePublish}
            disabled={(this.state.published && !this.state.unpublishedChanges) || this.state.publishing || isBlank || this.state.scheduled}
          >
            {!this.state.published && !this.state.scheduled && 'Publish'}
            {!this.state.published && this.state.scheduled && 'Scheduled'}
            {this.state.published && !this.state.unpublishedChanges && 'Published'}
            {this.state.published && this.state.unpublishedChanges && 'Publish changes'}
          </button>

          {this.state.published && !this.state.unpublishedChanges ? (
            <a className='button -small -secondary editor_desktop-only ml-16' href={this.state.url} target={this.props.initialDocument.id}>
              <span>View</span>
            </a>
          ) : (
            <a
              className='button -small -secondary editor_desktop-only ml-16'
              href={this.state.previewUrl}
              target={this.props.initialDocument.id}
            >
              <span>Preview</span>
            </a>
          )}
          <div>
            <Dropdown
              isOpen={this.state.dropdownOpen}
              onToggle={() => {
                this.setState({ dropdownOpen: !this.state.dropdownOpen, settingsOpen: false })
              }}
              renderToggle={(isOpen, onToggle) => (
                <button className='button -small -secondary -square ml-16' onClick={onToggle}>
                  <MoreIcon />
                </button>
              )}
            >
              <Menu>
                {this.state.published && !this.state.unpublishedChanges ? (
                  <a className='menu_item editor_mobile-only' href={this.state.url} target={this.props.initialDocument.id}>
                    <ViewIcon />
                    View
                  </a>
                ) : (
                  <a className='menu_item editor_mobile-only' href={this.state.previewUrl} target={this.props.initialDocument.id}>
                    <ViewIcon />
                    Preview
                  </a>
                )}

                {!this.state.home && this.state.published && (
                  <button
                    className='menu_item'
                    onClick={() => {
                      this.setState({ dropdownOpen: false })
                      this.handleUnpublish()
                    }}
                    disabled={this.state.unpublishing}
                  >
                    <UnpublishIcon />
                    Unpublish
                  </button>
                )}

                <button
                  className='menu_item'
                  onClick={() => {
                    this.setState({ settingsOpen: true, dropdownOpen: false })
                  }}
                >
                  <SettingsIcon /> Settings
                </button>
              </Menu>
            </Dropdown>

            <Dropdown
              wide
              onToggle={open => {
                this.setState({ settingsOpen: open })
              }}
              isOpen={this.state.settingsOpen}
            >
              <form onSubmit={this.handleSaveSettings} className='p-24 stack -gap-16'>
                <div className='field mb-24'>
                  <div
                    className='editor_menu-image'
                    style={
                      this.state.image_url || firstImage
                        ? { backgroundImage: `url(${this.state.image_url || firstImage})`, paddingBottom: '50%' }
                        : {}
                    }
                  >
                    <div
                      className='editor_menu-image-actions'
                      style={!this.state.image_url && !firstImage ? { backgroundImage: 'none', boxShadow: 'none' } : {}}
                    >
                      <label className='button -secondary -smaller' style={!this.state.image_url && !firstImage ? { margin: 'auto' } : {}}>
                        <input type='file' id={'e'} accept='.png,.jpeg,.jpg,.gif' onChange={this.handleUploadImage} />
                        {this.state.image_url || firstImage ? 'Choose another image' : 'Choose image'}
                      </label>

                      {this.state.image_url && (
                        <a
                          className='button -smaller -destructive ml-8'
                          onClick={() => {
                            this.setState({ image_url: null, settingsSaved: false })
                          }}
                        >
                          Remove
                        </a>
                      )}
                    </div>
                  </div>
                </div>

                {this.props.initialDocument.type === 'post' && (
                  <div className='field' style={{ maxWidth: 336 }}>
                    <div style={{ maxWidth: 186 }}>
                      <label className='field_label' htmlFor='plublishedAt'>
                        {this.state.published ? 'Published at' : 'Publish at'}
                      </label>

                      <DatePicker date={this.state.date} onDateChange={this.handleDateChange} locale={enGB} format='yyyy-MM-dd HH:mm'>
                        {({ inputProps, focused }) => (
                          <input
                            className={'input -small' + (focused ? ' -focused' : '')}
                            name='plublishedAt'
                            id='plublishedAt'
                            autoComplete='off'
                            {...inputProps}
                            onFocus={event => {
                              window.dispatchEvent(new Event('resize'))
                              inputProps.onFocus(event)
                            }}
                            readOnly={false}
                          />
                        )}
                      </DatePicker>
                    </div>
                  </div>
                )}

                {this.props.initialDocument.type === 'post' && (
                  <div className='field'>
                    <label className='field_label' htmlFor='tags'>
                      Tags
                    </label>

                    <MultiSelect
                      value={this.state.tags}
                      options={this.props.tags.map(tag => ({ value: tag.name, label: tag.name }))}
                      onChange={value => {
                        this.setState({ tags: value, settingsSaved: false })
                      }}
                    />
                  </div>
                )}

                {!this.state.home && (
                  <Field
                    small
                    label='URL slug'
                    name='slug'
                    placeholder={potentialSlug}
                    value={this.state.slug || ''}
                    onChange={this.handleFieldChange('slug')}
                    help={this.props.siteUrl.split('//')[1] + '/' + (this.state.slug || potentialSlug)}
                    autoComplete='off'
                  />
                )}

                {this.props.initialDocument.type === 'post' && (
                  <div className='field'>
                    <label className='field_label' htmlFor='tags'>
                      Author
                    </label>

                    <select className='select -small' value={this.state.user_id} onChange={this.handleFieldChange('user_id')}>
                      <option value=''>None</option>

                      {this.props.users.map(user => (
                        <option key={user.id} value={user.id}>
                          {user.name || user.email}
                        </option>
                      ))}
                    </select>
                  </div>
                )}

                <div className='field'>
                  <label className='field_label' htmlFor='excerpt'>
                    Excerpt
                  </label>

                  <Textarea
                    className='textarea -small'
                    name='excerpt'
                    id='excerpt'
                    value={this.state.excerpt}
                    onChange={this.handleFieldChange('excerpt')}
                  />
                </div>

                {!this.state.home && (
                  <a className='text -smaller mt-16 block' onClick={this.handleSettingsAdvancedToggle}>
                    {this.state.settingsAdvanced ? 'Hide' : 'Show'} advanced settings
                  </a>
                )}

                {(this.state.settingsAdvanced || this.state.home) && (
                  <>
                    <div className='field mt-16'>
                      <label className='field_label' htmlFor='code_head'>
                        Code injection
                      </label>

                      <Textarea
                        className='textarea -small'
                        name='code_head'
                        id='code_head'
                        value={this.state.code_head}
                        onChange={this.handleFieldChange('code_head')}
                        disabled={!this.props.pro}
                      />

                      <span className='field_help'>
                        This will go in the <code>&lt;head&gt;</code> tag for this {this.props.initialDocument.type}.{' '}
                        {!this.props.pro && <a href='/settings/subscription'>Upgrade to Pro</a>}.
                      </span>
                    </div>
                  </>
                )}

                <button className='button -small -wide mt-24' disabled={this.state.settingsSaved || this.state.settingsSaving}>
                  Save
                  {isFuture(this.state.date) && !this.state.scheduled && ' and schedule'}
                  {!this.state.published && isPast(this.state.date) && ' and publish'}
                  {this.state.published && !this.state.date && ' and unpublish'}
                </button>
              </form>
            </Dropdown>
          </div>
        </div>

        <span className='editor_status'>{this.state.saved && !this.state.saving ? 'Saved' : 'Saving...'}</span>

        {this.props.newEditor ? (
          <RichEditor text={this.state.text} onTextChange={this.handleTextChange} />
        ) : (
          <MarkdownEditor
            title={this.state.title}
            text={this.state.text}
            onTitleChange={this.handleTitleChange}
            onTextChange={this.handleTextChange}
          />
        )}
      </div>
    )
  }
}
