import React from 'react'
import classNames from 'classnames'

const OptionButton = React.forwardRef(({ children, className, style, disabled, ...otherProps }, ref) => (
  <label className={classNames('option-button', className, { '-disabled': disabled })} style={style}>
    <input type='radio' ref={ref} {...otherProps} />
    <div className='option-button_content'>{children}</div>
  </label>
))

export default OptionButton
